.helper {
  background: url(../img/master/main_bg.jpg) no-repeat;
  -webkit-background-size: auto;
  background-size: cover;
  position: relative;
}
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  height: 100%;
  overflow: visible;
  background: #000000;
  @include breakpoint($xs) {
    position: absolute;
  }
  & img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    margin: auto;
    @include breakpoint($xs) {
      position: relative;
      top: 50vh;
      transform: translateY(-10%);
    }
  }
}
.scroll-to-top {
  width: 50px;
  height: 50px;
  font-family: 'FontAwesome';
  font-size: 48px;
  text-align: center;
  text-decoration: none;
  position: fixed;
  bottom: 50px;
  left: 20px;
  display: none;
  z-index: 100;
  transition: transform 0.3s;
  overflow: hidden;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
  background-image: -webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
  background-image: -ms-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
  background-image: -webkit-gradient(linear, right top, right bottom, color-stop(0%, #fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.chat {
  position: fixed;
  right: 3px;
  top: 380px;
  height: 67px;
  width: 175px;
  display: flex;
  align-items: center;
  background: url(../img/master/chat.png) no-repeat;
  z-index: 3;
  transform: rotate(-90deg);
  transform-origin: right bottom;
  & a {
    color: #cbc395;
    font-size: 18px;
    line-height: normal;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    width: 130px;
  }
  &:hover {
    -webkit-filter: brightness(140%);
    filter: brightness(140%);
  }
  @include breakpoint($xs) {
    position: fixed;
    top: 90%;
    right: 35px;
    background: url(../img/master/chat-horizont.png) no-repeat;
    transform: rotate(0deg);
    transform-origin: right bottom;
  }
}
.reg {
  position: relative;
  @include breakpoint($xs) {
    display: none;
  }
}
.content {
  background: url(../img/master/content_bg.png) center top repeat-y;
  &__layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 65vh;
    @include breakpoint($sm) {
      flex-direction: column;
    }
    @include breakpoint($xs) {
      flex-direction: column;
    }
  }
  &--left {
    flex: 0 1 200px;
    @include breakpoint($md) {
      flex: 0 1 210px;
    }
  }
  &--right {
    flex: 0 1 970px;
    @include breakpoint($sm) {
      flex: auto;
    }
    @include breakpoint($xs) {
      flex: auto;
    }
  }
  @include breakpoint($md) {
    background: url(../img/master/content_tablet_bg.png) center top repeat-y;
  }
  @include breakpoint($sm) {
    background: url(../img/master/content_portrait_bg.png) center top repeat-y;
    flex: auto;
  }
}


//INNER PAGES TITLE

.page__title {
  display: block;
  text-align: center;
  font: {
    family: "28 days", sans-serif;
    size: 30px;
    weight: 400;
  }
  text-transform: uppercase;
  padding: {
    top: 20px;
    bottom: 50px;
  };
}
.about {
  p {
    font-size: 16px;
    line-height: 1.4em;
    text-align: justify;
    padding-bottom: 20px;
  }
}
/*=========================*/
//NOTIFICATION PAGE
/*==========================*/

.notification {
  &__layout {
    min-height: 870px;
    padding: {
      top: 30px;
      left: 90px;
      right: 90px;
    }
    text-align: center;
    & .title {
      font-family: "28 days", sans-serif;
      font-size: 30px;
      font-weight: 400;
      text-transform: uppercase;
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }
  &__text {
    font-size: 16px;
    padding-bottom: 45px;
    max-width: 700px;
    margin: auto;
  }
}

/*=========================*/
//COLUMNS LAYOUT
/*==========================*/

.columns {

}

/*====================================*/
/*================SLIDER==============*/
/*====================================*/
.slider-mask {
  position: relative;
}
.slider {
  position: relative;
  overflow: hidden;
  background-color: black;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background: url(../img/master/slider_divider_bg.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
  }
}

.slick-arrow {
  position: absolute;
}

.slick-slide {
  outline: none;
  display: flex;
  justify-content: center;
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
}

.slick-dots {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: absolute;
  background: url(../img/master/slick_dots_bg.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 396px;
  height: 21px;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 20px;
  transition: all .3s;

  li {
    height: 8px;
    flex: 0 1 100%;
    text-indent: -9999px;
    cursor: pointer;
    transition: all .3s;

    &.slick-active {
      background: #fff;
      box-shadow: 0 0 9px 0 rgba(255,255,255,1);
      border-radius: 5px;
      transition: all .3s;
    }
  }
}
/*====================================*/
/*===========FEATURED GAMES===========*/
/*====================================*/
.notification__text.no-fav-games {
  padding: 0;
  text-align: center;
}
.featured-games {
  visibility: hidden;
  opacity: 0;
  padding-bottom: 65px;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  transition: all .5s;
  height: 345px;
  box-sizing: border-box;
  &.displayed {
    visibility: visible;
    opacity: 1;
  }
  &__layout {
    padding-top: 45px;
    &__title {
      text-align: center;
      padding-bottom: 20px;
      user-select: none;
      font: 30px "28 Days", sans-serif;
      text-transform: uppercase;
    }
  }
  &__slider {
    padding: 0 60px;
    @include breakpoint($md) {
      padding: 0 30px;
      margin: 0 10px;
    }
    @include breakpoint($sm) {
      padding: 0 30px;
      margin: 0 30px;
    }
    @include breakpoint($xs) {
      padding: 0 15px;
      margin: 0 20px;
    }
    &__slide {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 0 15px;
      @include breakpoint($xs) {
        margin: 0 10px;
      }
    }
  }
  & .close-button {
    width: 19px;
    height: 18px;
    background: url(../img/master/close-button.png);
    position: absolute;
    top: 7px;
    right: 5px;
    user-select: none;
    &:hover {
      filter: brightness(140%);
    }
  }

  & .fa-times {
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    color: #959595;
    opacity: 0.5;
    font-family: 'FontAwesome';
    font-size: 20px;
    position: absolute;
    right: 35px;
    top: 35px;
    @include breakpoint($xs) {
      right: 20px;
    }
    &:hover {
      opacity: 1;
    }

    &::after {
      content: "\f00d";
      display: block;
      font-family: 'FontAwesome';
      text-transform: uppercase;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
      background-image: linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -ms-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -webkit-gradient(linear, right top, right bottom, color-stop(0%, #fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 2px 1px rgba(0, 0, 0, 0.3);
    }
  }
  & .fa-arrow-circle-up {
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    color: #959595;
    opacity: 0.5;
    font-family: 'FontAwesome';
    font-size: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 20px;
    user-select: none;
    outline: none;

    &:hover {
      opacity: 1;
    }

    &::after {
      content: "\f0aa";
      display: block;
      font-family: 'FontAwesome';
      text-transform: uppercase;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
      background-image: linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -ms-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -webkit-gradient(linear, right top, right bottom, color-stop(0%, #fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 2px 1px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
    }
  }
  & .slick-arrow {
    position: absolute;
    background: url(../img/master/winners_arrows_bg.png) no-repeat;
    text-indent: -9999px;
    height: 19px;
    width: 18px;
    top: -webkit-calc(50% - 15px);
    top: calc(50% - 15px);
    z-index: 10;
    opacity: .7;
  &:hover {
    opacity: 1;
  }
  }

  & .slick-arrow.slick-prev {
    left: 0;
    background-position: left center;
  }
  & .slick-arrow.slick-next {
    right: 0;
    background-position: right center;
  }
}

/*====================================*/
/*================GAMES===============*/
/*====================================*/

.games {
  background: url(../img/master/games_bg.png) center top -4px no-repeat;
  padding: {
    bottom: 10px;
  };

  &__menu {
    background: url(../img/master/games_menu_bg.png) center bottom no-repeat;
    padding-bottom: 6px;
    @include breakpoint($xs) {
      background: none;
      padding-bottom: 0;
      display: flex;
    }

    &__list {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      @include breakpoint($xs) {
        display: none;
        margin-top: 5px;
        position: absolute;
        width: 100%;
        z-index: 1;
        -webkit-transition: all .3s;
        transition: all .3s;
        flex-direction: column;
      }
    }

    &__item {
      width: 195px;
      height: 52px;
      flex: 0 1 195px;
      background: url(../img/master/btn_sec_bg.png) no-repeat;
      background-size: 100% auto;
      @include breakpoint($md){
        background: url(../img/master/btn_sec_tablet_bg.png) no-repeat;
        background-position: center top;
      }
      @include breakpoint($sm) {
        background: url(../img/master/btn_sec_tablet_portrait_bg.png) no-repeat;
        background-position: center top;
      }
      @include breakpoint("(max-width: 900px)"){

      }
      @include breakpoint($xs) {
        flex: 0 1 52px;
        width: 100%;
        height: 52px;
        background: url(../img/master/games_group_bg.png) no-repeat;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.75);
      }
      &:hover {
        background-position: center center;
      }

      &:focus, &:active, &:visited, &_active {
        background-position: center bottom;
      }

    }

    &__link {
      font: 18px $font;
      color: #cbc395;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-shadow: 0 3px 1px rgba(0, 0, 0, 0.6), 0 0 12px rgba(0, 0, 0, 0.6);
      word-break: break-word;
      text-align: center;

      &:hover {
        color: #fff;
      }
    }
  }

  &__layout {
    display: flex;
    justify-content: space-between;
    @include breakpoint($sm) {
      flex-direction: column;
    }
    @include breakpoint($xs) {
      flex-direction: column;
    }
  }

  &--left {
    flex: 0 1 780px;
    margin: {
      top: 25px;
      left: 15px;
      right: 15px;
    };
    @include breakpoint($md) {
      flex: 0 1 655px;
      margin-right: 12px;
    }
    @include breakpoint($sm) {
      margin: {
        top: 30px;
        left: 20px;
        right: 20px;
      };
    }
    @include breakpoint($xs) {
      margin: {
        top: 30px;
        left: 25px;
        right: 25px;
      };
    }
  }

  &--right {
    flex: 0 1 360px;
    margin: {
      top: 25px;
      right: 15px;
      left: 15px;
    };
    @include breakpoint($md){
      flex: 0 1 310px;
      margin: {
        left: 12px;
        right: 12px;
      };
    }
    @include breakpoint($xs){
      flex: 0 1 310px;
      margin: {
        left: 25px;
        right: 25px;
        bottom: 30px;
      };
    }
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 750px;
    @include breakpoint($sm) {
      max-width: 720px;
    }
    @include breakpoint($xs) {
      max-width: 590px;
    }

    &__name {
      text-align: center;
      display: inline-block;
      width: calc(100% - 25px);
      text-indent: 18px;
      color: #cbc395;
      vertical-align: top;
      margin: {
        top: 6px;
      };
      font: 16px $font;
    }

    &__wrap {
      position: relative;
    }

    &__wrap:hover .games__list__item__overlay {
      visibility: visible;
      opacity: 1;
    }
    &__item {
      width: 32%;
      outline: none;
      margin: {
        bottom: 20px;
      }
      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        transition: all .5s;
        & > * {
          margin-bottom: 5px;
          @include breakpoint($xs) {
            margin-bottom: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .fa-info-circle {
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
          color: #959595;
          font-family: FontAwesome;
          font-size: 20px;
          position: absolute;
          left: 5px;
          top: 5px;
        }
        .fa-info-circle::after {
          content: "\f05a";
          display: block;
          font-family: 'FontAwesome';
          text-transform: uppercase;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background-color: #fff;
          filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
          background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
          background-image: linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
          background-image: -webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
          background-image: -ms-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
          background-image: -webkit-gradient(linear, right top, right bottom, color-stop(0%, #fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }

    &__item:hover &__name {
      color: #fffbac;
    }

    &__link {
      display: block;
      height: 100%;
      position: relative;
      box-shadow: 0 1px 1px 0 rgba(0,0,0,1);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparent;
        z-index: 3;
        box-shadow: inset 0px -1px 1px 0px rgba(255,255,255,0.75);
      }

      &--favourite {
        display: inline-block;
        width: 18px;
        height: 17px;
        margin: {
          top: 9px;
        };
        background: url(../img/master/favourite_bg.png) right center no-repeat;

        &.active {
          background-position: left center;
        }
      }
    }

    &__rank {
      display: flex;
      justify-content: center;

      &__item {
        width: 18px;
        height: 17px;
        background: url(../img/master/rank_bg.png) right center no-repeat;
        margin: {
          right: 2px;
        };
        -webkit-filter: contrast(120%) brightness(120%);
        filter: contrast(120%) brightness(120%);

        &:last-of-type {
          margin: {
            right: 0;
          };
        }

        &.filled {
          background-position: left center;
        }
        &.halffilled {
          background-position: center center;
        }

        a {
          display: block;
          height: 100%;
        }
      }
    }
  }

  &__search {
    position: relative;
    z-index: 0;


    &__field {
      height: 45px;
      width: 100%;
      background: url(../img/master/forms/search_left_bg.png) left top no-repeat,
      url(../img/master/forms/search_right_bg.png) right top no-repeat;
      border: none;
      outline: none;
      box-sizing: border-box;
      color: #ffffff;
      font: 16px $font;
      padding: {
        left: 22px;
        right: 45px;
        bottom: 7px;
      };
      &:focus {
        background-position: left -60px, right -60px;
      }
      &:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
      @include placeholder {
        color: #999999;
        font: italic 16px $fontLight;
      }
    }
    @include breakpoint($sm) {
      display: none;
    }
    @include breakpoint($xs) {
      display: none;
    }
    &--copy {
      display: none;
      @include breakpoint($sm) {
        display: block;
        margin-bottom: 30px;
      }
      @include breakpoint($xs) {
        display: block;
        margin-bottom: 30px;
      }
    }
    &__submit {
      display: block;
      position: absolute;
      height: 26px;
      width: 25px;
      background: url(../img/master/search_submit_bg.png) center top no-repeat;
      cursor: pointer;
      border: none;
      outline: none;
      text-indent: -9999px;
      right: 9px;
      top: 48%;
      transform: translateY(-50%);
    }

    &__field:focus ~ &__submit {
      background-position: center bottom;
    }
  }
}
form.form.form--games {
  &::before {
    content: "";
    background: url(../img/master/forms/search_bg.png) left top repeat-x;
    position: absolute;
    display: block;
    width: -webkit-calc(100% - 56px);
    width: calc(100% - 56px);
    left: 28px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  &.focused::before {
    background-position: left -60px;
  }
}
.group-games {
  position: relative;
}
.providers-games {
  position: relative;
  & ul {
    display: none;
    margin-top: 5px;
    position: absolute;
    width: 100%;
    z-index: 1;
    -webkit-transition: all .3s;
    transition: all .3s;
    background-color: rgba(0, 0, 0, 0.75);
  }
  & ul li {
    width: 100%;
    height: 52px;
    background: url(../img/master/games_group_bg.png) no-repeat;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  & ul li a {
    font: 18px "Roboto", sans-serif;
    color: #cbc395;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-shadow: 0 3px 1px rgba(0, 0, 0, 0.6), 0 0 12px rgba(0, 0, 0, 0.6);
    &:hover {
      color: #ffffff;
    }
  }
}

/*====================================*/
/*================JACKPOT=============*/
/*====================================*/

.jackpot {
  width: 362px;
  height: 437px;
  background: url(../img/master/jackpot_bg.png) no-repeat;
  @include breakpoint($md) {
    width: 315px;
    height: 440px;
    background: url(../img/master/jackpot_tablet_bg.png) no-repeat;
  }
  margin: {
    top: 30px;
  };
  overflow: hidden;

  &__layout {
    margin: {
      top: 74px;
    };
  }

  &__item {
    $item: &;
    margin: {
      bottom: 8px;
    };


    &__text {
      font: bold 18px $fontBookmanBold;
      text-shadow: 0 2px 3px black;
      text-align: center;
      margin: {
        bottom: 5px;
      };
    }

    &__content {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      width: 302px;
      height: 65px;
      @include breakpoint($md) {
        width: 251px;
        height: 60px;
      }
      @include breakpoint($xs) {
        width: 236px;
        height: 60px;
        font-size: 34px;
      }
      margin: {
        left: auto;
        right: auto;
      };
      text-shadow: 0 4px 4px #000000;
      font: bold 36px $fontAeroBold;
      line-height: 60px;
    }

    &__currency {
      margin: {
        right: 10px;
      };
    }

    &--gold {
      #{$item}__content {
        background: url("../img/master/jackpot_gold_bg.png") no-repeat;
        @include breakpoint($md){
          background: url("../img/master/jackpot_gold_tablet_bg.png") no-repeat;
        }
        @include breakpoint($xs){
          background: url("../img/master/jackpot_gold_mobile_bg.png") no-repeat;
        }
      & p {
        background: repeating-linear-gradient(#ffc20e 9px, #fff 18px);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
      }
      }

      #{$item}__text {
        color: #ffc20e;
      }
    }

    &--silver {
      #{$item}__content {
        background: url("../img/master/jackpot_silver_bg.png") no-repeat;
        @include breakpoint($md){
          background: url("../img/master/jackpot_silver_tablet_bg.png") no-repeat;
        }
        @include breakpoint($xs){
          background: url("../img/master/jackpot_silver_mobile_bg.png") no-repeat;
        }
        & p {
          background: repeating-linear-gradient(#fff 9px, #999999 18px);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
        }
      }

      #{$item}__text {
        color: #999999;
      }
    }

    &--bronze {
      #{$item}__content {
        background: url("../img/master/jackpot_bronze_bg.png") no-repeat;
        @include breakpoint($md){
          background: url("../img/master/jackpot_bronze_tablet_bg.png") no-repeat;
        }
        @include breakpoint($xs){
          background: url("../img/master/jackpot_bronze_mobile_bg.png") no-repeat;
        }
        & p {
          background: repeating-linear-gradient(#fff 9px, #955e3d 18px);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
        }
      }

      #{$item}__text {
        color: #955e3d;
      }
    }
  }
}
/*====================================*/
/*=============WIDGET BLOCK===========*/
/*====================================*/
.widget_block {
  @include breakpoint($sm) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .jackpot {
      margin-top: 0;
      width: 348px;
      height: 430px;
      background: url(../img/master/jackpot_tablet_partrait_bg.png) no-repeat;
    }
    .winners {
      position: relative;
      margin-top: 0;
      width: 348px;
      height: 430px;
      background: url(../img/master/winners_tablet_portrait_bg.png) no-repeat;
    }
  }

  @include breakpoint($xs) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .jackpot {
      margin-top: 0;
      width: 284px;
      height: 430px;
      background: url(../img/master/jackpot_mobile_bg.png) no-repeat;
    }
    .winners {
      margin-top: 0;
      width: 284px;
      height: 430px;
      background: url(../img/master/winners_mobile_bg.png) no-repeat;
    }
  }
}

/*====================================*/
/*================WINNERS=============*/
/*====================================*/

.winners {
  width: 362px;
  height: 343px;
  background: url(../img/master/winners_bg.png) no-repeat;
  overflow: hidden;
  position: relative;
  @include breakpoint($md) {
    width: 315px;
    height: 370px;
    background: url(../img/master/winners_tablet_bg.png) no-repeat;
  }
  margin: {
    top: 28px;
  };

  &__title {
    font: bold 20px $fontBookmanBold;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    margin: {
      top: 20px;
      bottom: 15px;
    };
    @include breakpoint($md) {
      padding: 0 50px;
    }
    p {
      position: relative;
      text-shadow: 0 2px 3px black;
    }

    p::before {
      content: attr(data-copy);
      display: block;
      font: bold 20px $fontBookmanBold;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      position: absolute;
      left: 0;
      right: 0;
      background-color:#fff;
      filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
      background-image:-moz-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-o-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-ms-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-webkit-gradient(linear, right top, right bottom, color-stop(0%,#fff), color-stop(40%,#d1bd8f),color-stop(61%,#d1bd8f),color-stop(84%,#8d7133));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
    }
  }

  &__slider {
    max-width: 248px;
    margin: {
      left: auto;
      right: auto;
    };
    @include breakpoint($xs) {
      max-width: 224px;
    }
  }

  .slick-arrow {
    position: absolute;
    background: url(../img/master/winners_arrows_bg.png) left center no-repeat;
    text-indent: -9999px;
    height: 19px;
    width: 18px;
    top: calc(50% - 15px);

    @include breakpoint($md) {
      top: -45px;
    }
    @include breakpoint($sm) {
      top: calc(50% - 14px);
    }
    @include breakpoint($xs) {
      top: -42px;
    }
    &.slick-prev {
      left: -34px;
      @include breakpoint($md) {
        left: -7px;
      }
      @include breakpoint($sm) {
        left: -30px;
      }
      @include breakpoint($xs) {
        left: 0;
      }
    }

    &.slick-next {
      right: -34px;
      background-position: right center;
      @include breakpoint($md) {
        right: -7px;
      }
      @include breakpoint($xs) {
        right: 0;
      }
    }
  }

  &__slide {
    width: 248px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between !important;

    &__image {
      max-width: 124px;
      max-height: 77px;
      text-align: left;
      margin-bottom: 10px;
      @include breakpoint($xs) {
        max-width: 112px;
      }
    }

    &__content {
      max-width: 110px;
      text-align: right;
    }

    &__game {
      color: #cbc395;
      text-shadow: 0 1px 2px black;
      font: 16px $font;
      margin: {
        bottom: 6px;
      };
      line-height: 1;
    }

    &__result {
      color: #fff;
      text-shadow: 0 1px 2px black;
      font: 16px $font;
      margin: {
        bottom: 5px;
      };
      line-height: 1;
    }

    &__name {
      color: #907764;
      font: 16px $font;
      text-shadow: 0 1px 2px black;
      line-height: 1;
    }
  }
}

/*====================================*/
/*=============TOURNAMENT=============*/
/*====================================*/

.tournament {
  margin: {
    top: 20px;
  };
  @include breakpoint($sm) {
    margin-bottom: 30px;
  }
  &__subtitle {
    font: 16px $font;
    text-align: center;
    display: flex;
    align-items: center;

    p {
      position: relative;
      text-shadow: 0 2px 3px black;
      line-height: 1;
    }

    p::before {
      content: attr(data-copy);
      display: block;
      font: 16px $font;
      text-align: center;
      line-height: 1;
      position: absolute;
      left: 0;
      right: 0;
      background-color:#fff;
      filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
      background-image:-moz-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-o-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-ms-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-webkit-gradient(linear, right top, right bottom, color-stop(0%,#fff), color-stop(40%,#d1bd8f),color-stop(61%,#d1bd8f),color-stop(84%,#8d7133));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
    }

    &::before, &::after {
      content: "";
      flex-grow: 1;
      background: url(../img/master/tournament_title_bg.png) no-repeat;
      height: 1px;
      box-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    }

    &::before {
      margin-right: 8px;
    }
    &::after {
      margin-left: 8px;
    }

  }
  &__image {
    @include breakpoint($md) {
      width: 310px;
      margin: auto;
      }
    & img {
      @include breakpoint($sm) {
        min-width: 100%;
      }
      @include breakpoint($xs) {
        min-width: 100%;
      }
    }
  }
  &__title {
    font: bold 20px $fontBookmanBold;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    margin: {
      top: 10px;
      bottom: 20px;
    };

    p {
      position: relative;
      text-shadow: 0 2px 3px black;
    }

    p::before {
      content: attr(data-copy);
      display: block;
      font: bold 20px $fontBookmanBold;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      position: absolute;
      left: 0;
      right: 0;
      background-color:#fff;
      filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
      background-image:-moz-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-o-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-ms-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-webkit-gradient(linear, right top, right bottom, color-stop(0%,#fff), color-stop(40%,#d1bd8f),color-stop(61%,#d1bd8f),color-stop(84%,#8d7133));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
    }
  }

  &__content {
    height: 83px;
    background: url(../img/master/tournament_bg.png) center top no-repeat;
    margin: {
      top: -3px;
    };
    display: flex;
    justify-content: space-between;

    @include breakpoint($md) {
      height: 95px;
      background: url(../img/master/tournament_tablet_bg.png) center top no-repeat;
    }
    @include breakpoint($sm) {
      background: url(../img/master/tournament_table_portrait_bg.png) center top no-repeat;
    }
    @include breakpoint($xs) {
      background: url(../img/master/tournament_mobile_bg.png) center top no-repeat;
    }
    &--left, &--right {
      width: 50%;
      text-align: center;
    }

    &__title {
      color: #cbc395;
      font: 16px $fontLight;
      margin: {
        top: 12px;
      };
      text-shadow: 0 1px 2px black;

      @include breakpoint($md) {
        padding: 0 20px;
      }
    }

    &__result {
      margin: {
        top: 7px;
      };

      p {
        color: #fff;
        font: 24px $font;
      }

      span {
        color: #999999;
        font: 16px $fontLight;
        padding: {
          left: 3px;
          right: 5px;
        };
      }
    }
  }
}

/*====================================*/
/*=============POPULAR GAME===========*/
/*====================================*/
.populargame {

  margin: {
    top: 20px;
  }
;

  &__subtitle {
    font: 16px $font;
    text-align: center;
    display: flex;
    align-items: center;

    p {
      position: relative;
      text-shadow: 0 2px 3px black;
      line-height: 1;
    }

    p::before {
      content: attr(data-copy);
      display: block;
      font: 16px $font;
      text-align: center;
      line-height: 1;
      position: absolute;
      left: 0;
      right: 0;
      background-color: #fff;
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#fff, endColorstr=#d1bd8f);
      background-image: -moz-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -o-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -ms-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
      background-image: -webkit-gradient(linear, right top, right bottom, color-stop(0%, #fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
    }

    &::before, &::after {
        content: "";
        flex-grow: 1;
        background: url(../img/master/tournament_title_bg.png) no-repeat;
        height: 1px;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    }
    &::before {
      margin-right: 8px;
    }
    &::after {
      margin-left: 8px;
    }

  }
  &__title {
    font: bold 20px $fontBookmanBold;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    margin: {
      top: 10px;
      bottom: 20px;
    };

    p {
      position: relative;
      text-shadow: 0 2px 3px black;
    }

    p::before {
      content: attr(data-copy);
      display: block;
      font: bold 20px $fontBookmanBold;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      position: absolute;
      left: 0;
      right: 0;
      background-color:#fff;
      filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
      background-image:-moz-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-o-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-ms-linear-gradient(top, #fff 0%, #d1bd8f 40%,#d1bd8f 61%,#8d7133 84%);
      background-image:-webkit-gradient(linear, right top, right bottom, color-stop(0%,#fff), color-stop(40%,#d1bd8f),color-stop(61%,#d1bd8f),color-stop(84%,#8d7133));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
    }
  }
  &__image {
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    position: relative;
    @include breakpoint($sm) {
      height: 220px;
      overflow: hidden;
      & img {
        width: 100%;
        top: -50px;
        position: relative;
      }
    }
    @include breakpoint($xs) {
      height: 220px;
      overflow: hidden;
      & img {
        width: 100%;
        top: -50px;
        position: relative;
      }
    }
    &:hover .games__list__item__overlay {
      visibility: visible;
      opacity: 1;
    }
  }
  & img {
    width: 100%;
  }
}


/*====================================*/
/*================GAMES ROOM==========*/
/*====================================*/
.games-room {

  &__layout {
    padding: {
      top: 60px;
      left: 20px;
      right: 20px;
    }
    @include breakpoint($sm) {
      padding-top: 50px;
    }
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
.room {
  width: 550px;
  padding: {
    bottom: 30px;
  }
  @include breakpoint($md) {
    width: 475px;
    padding: {
      bottom: 25px;
    }
  }
  @include breakpoint($sm) {
    width: 354px;
    padding: {
      bottom: 15px;
    }
  }
  @include breakpoint($xs) {
    width: 100%;
  }
}
.dealer {
  border: 1px solid #817867;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  color: #ffffff;
  height: 100%;
  @include breakpoint($sm) {
    border: none;
    box-shadow: none;
  }
}
.dealer:hover .games__list__item__overlay {
  visibility: visible;
  opacity: 1;
}
#overlay {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0,0,0, .6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.dealer:hover {

  #overlay {
    opacity: 1;
  }
}
.room__description {
  margin-left: 40px;
  @include breakpoint($sm) {
    width: 130px;
    margin: 0;
  }
}
.room__status {
  color: #25b711;
  font-size: 16px;
  padding: {
    top:20px;
    bottom: 40px;
  }
  &--offline {
    color: #e90c0c;
  }
  @include breakpoint($sm) {
    padding: {
      top:10px;
      bottom: 10px;
    }
  }
}
.room__params {
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  @include breakpoint($sm) {
    line-height: 22px;
  }
}
.game_preview {
  position: relative;
  height: auto;
  width: auto;
  background: url("../img/master/modal-bg.png") center center;
  background-color: #5a5858;
  border: solid 1px #a09887;
  display: flex;
  justify-content: flex-start;
  padding: 35px;
  &::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: 9px;
    height: calc(100% + 4px);
    background: url("../img/master/left-top.png") left -1px top no-repeat, url("../img/master/left-bottom.png") left -1px bottom -2px no-repeat;
  }
  &::after {
    content: '';
    position: absolute;
    right: -1px;
    top: -1px;
    bottom: -1px;
    width: 9px;
    height: calc(100% + 4px);
    background: url("../img/master/right-top.png") right -1px top no-repeat, url("../img/master/right-bottom.png") right -1px bottom -2px no-repeat;
  }
  @include breakpoint($md) {
    padding: 20px;
    background-size: 100% 100%;
  }
  @include breakpoint($sm) {
    height: 248px;
    padding: 17px 15px;
    background-size: 100% 100%;
    justify-content: space-between;
  }
  @include breakpoint($xs) {
    justify-content: center;
  }
}
.back-button {
  text-align: center;
  margin: {
    top: 20px;
    bottom: 50px;
  };
}


/*====================================*/
/*================ABOUT===============*/
/*====================================*/

.about {
  &__layout {
    padding: {
      top: 30px;
      left: 45px;
      right: 45px;
      bottom: 115px;
    }

    @include breakpoint($md) {
      padding: {
        top: 30px;
        left: 70px;
        right: 70px;
        bottom: 70px;
      }
    }
    @include breakpoint($sm) {
      padding: {
        top: 30px;
        left: 40px;
        right: 40px;
        bottom: 80px;
      }
    }
    @include breakpoint($xs) {
      padding: {
        top: 30px;
        left: 40px;
        right: 40px;
        bottom: 90px;
      }
    }
  }
}
.paragraph {
  font-size: 16px;
  line-height: 1.4em;
  text-align: justify;
  padding-bottom: 20px;
  @include breakpoint($sm) {
    font-size: 18px;
  }
  @include breakpoint($xs) {
    font-size: 18px;
  }
}

/*====================================*/
/*================CONTACT=============*/
/*====================================*/
.contact {

  &__layout {
    padding: {
      top: 30px;
      left: 90px;
      right: 90px;
      bottom: 80px;
    }
    @include breakpoint($md) {
      padding: {
        top: 30px;
        left: 70px;
        right: 70px;
        bottom: 120px;
      }
    }
    @include breakpoint($sm) {
      padding: {
        top: 30px;
        left: 150px;
        right: 150px;
        bottom: 120px;
      }
    }
    @include breakpoint($xs) {
      padding: {
        top: 30px;
        left: 75px;
        right: 75px;
        bottom: 75px;
      }
    }
  }
  &__title {
    display: block;
    text-align: center;
    font: {
      family: "28 days", sans-serif;
      size: 30px;
      weight: 400;
    }
    text-transform: uppercase;
    padding: {
      top: 20px;
      bottom: 45px;
    };
  }
  &__subtitle {
    text-align: center;
    color: #d3b666;
    font-size: 18px;
    margin-bottom: 25px;
  }
  &__description {
    font-size: 16px;
    line-height: 1.4em;
    padding-right: 170px;
    margin-bottom: 10px;
    @include breakpoint($md) {
      padding-right: 90px;
    }
    @include breakpoint($sm) {
      padding-right: 15px;
    }
    @include breakpoint($xs) {
      padding-right: 5px;
    }
  p {
    padding-bottom: 15px;
  }
    & ul {
      padding-bottom: 15px;
      position: relative;
    & li {
      padding-left: 15px;
      &::before {
        content: "\2022";
        left: 0;
        position: absolute;
      }
    }
    }
  }

}

/*====================================*/
/*================BONUSES=============*/
/*====================================*/
.bonuses {
  &__layout {
    min-height: 60vh;
    padding: {
      top: 30px;
      left: 115px;
      right: 115px;
      bottom: 30px;
    }
    @include breakpoint($md){
      padding: {
        left: 75px;
        right:75px;
      };
    }
    @include breakpoint($sm) {
      padding: {
        left: 25px;
        right:25px;
      };
    }
    @include breakpoint($xs) {
      padding: {
        left: 25px;
        right:25px;
      };
    }
  }
  &__item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 80px;
    &__image {
      flex: 0 1 185px;
    }
    &__content {
      font-family: Roboto, sans-serif;
      flex: 0 1 635px;
      @include breakpoint($md) {
        flex: 0 1 700px;
      }
      @include breakpoint($sm) {
        flex: 0 1 510px;
      }
      @include breakpoint($xs) {
        flex: 0 1 380px;
      }
    }
    &__title {
      text-shadow: 0 2px 1px #000000;
      color: #d7c59d;
      font-family: "Bookman Old Style";
      font-size: 20px;
      font-weight: 700;
    }
    &__wager {
      font:{
        size: 16px;
      };
      padding: {
        top: 15px;
        bottom: 25px;
      };
    }
    &__details {
      font-size: 16px;
      line-height: 1.4em;
      margin-bottom: 28px;
      &__title {
        margin-bottom: 9px;
        border-bottom: solid 1px;
        display: inline-block;
        line-height: 16px;
        cursor: pointer;
        user-select: none;

        &::after {
          content: "\f0d7";
          display: inline-block;
          font: {
            family: FontAwesome;
          }
          margin-left: 5px;
        }

        &.rotated::after {
          transform: rotate(180deg);
          transition: transform .2s;;
        }
      }

      &__content {
        display: none;
      }
    }

  }
}


/*====================================*/
/*===========BONUSES-HISTORY==========*/
/*====================================*/
.bonuses-history {
    min-height: 90vh;
    padding: {
      top: 50px;
      left: 60px;
      right: 15px;
    }
  @include breakpoint($md) {
    padding-left: 30px;
  }
  @include breakpoint($sm) {
    padding: {
      top: 15px;
      left: 20px;
      right: 20px;
    };
  }
  @include breakpoint($xs) {
    padding: {
      top: 0;
      left: 25px;
      right: 25px;
    };
  }
  &__title {
    padding-bottom: 50px;
    font-family: "28 days", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
  }
  &__layout {
    padding: {
      bottom: 40px;
    };
  }
}

/*====================================*/
/*=========TRANSACTION-HISTORY========*/
/*====================================*/
.transaction-history {
  padding: {
    top: 50px;
    left: 60px;
    right: 15px;
  }
  @include breakpoint($md) {
    padding-left: 30px;
  }
  @include breakpoint($sm) {
    padding: {
      top: 15px;
      left: 20px;
      right: 20px;
    };
  }
  @include breakpoint($xs) {
    padding: {
      top: 0;
      left: 25px;
      right: 25px;
    };
  }
  &__title {
    padding-bottom: 50px;
    font-family: "28 days", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
  }
  &__layout {
    padding: {
      bottom: 40px;
    };
  }
}
/*====================================*/
/*================TERMS===============*/
/*====================================*/
.terms {
  &__layout {
    min-height: calc(100vh - 168px - 230px);
    padding: {
      top: 30px;
      left: 80px;
      right: 80px;
      bottom: 30px;
    }
    @include breakpoint($md) {
      padding: {
        top: 30px;
        left: 70px;
        right: 70px;
        bottom: 0;
      }
    }
    @include breakpoint($sm) {
      padding: {
        top: 30px;
        left: 40px;
        right: 40px;
        bottom: 30px;
      }
    }
    @include breakpoint($xs) {
      padding: {
        top: 30px;
        left: 40px;
        right: 40px;
        bottom: 30px;
      }
    }
  }
  &__title {
    display: block;
    text-align: center;
    font: {
      family: "28 days", sans-serif;
      size: 30px;
      weight: 400;
    }
    text-transform: uppercase;
    padding: {
      top: 20px;
      bottom: 50px;
    };
  }
  &__list {
    font-size: 16px;
    line-height: 1.4em;
    text-align: justify;
  &__item {
    margin-bottom: 35px;
  }
    h2 {
      padding-bottom: 15px;
    }
    p {
      padding: {
        top: 15px;
        bottom: 15px;
      };
    }
  }
}

/*====================================*/
/*================REGISTRATION========*/
/*====================================*/
.register {

  &__layout {
    padding: {
      top: 30px;
      left: 80px;
      right: 80px;
      bottom: 135px;
    }
    @include breakpoint($md) {
      padding: {
        top: 30px;
        left: 70px;
        right: 70px;
        bottom: 80px;
      }
    }
  }
  &__title {
    display: block;
    text-align: center;
    font: {
      family: "28 days", sans-serif;
      size: 30px;
      weight: 400;
    }
    text-transform: uppercase;
    padding: {
      top: 20px;
      bottom: 50px;
    };
  }

.login__social p {
  max-width: 600px;
  text-align: center;
  color: #d3b666;
  font-size: 20px;
  padding: 0 40px;
  margin: {
    left: auto;
    right: auto;
    bottom: 25px;
  }
}

}

/*====================================*/
/*================LOGIN===============*/
/*====================================*/
.login {

  &__layout {
    min-height: 870px;
    padding: {
      top: 30px;
    };
  }
  &__title {
    display: block;
    text-align: center;
    font: {
      family: "28 days", sans-serif;
      size: 30px;
      weight: 400;
    }
    text-transform: uppercase;
    padding: {
      top: 20px;
      bottom: 50px;
    };
  }
  &__social {
    margin-bottom: 30px;
  }
  &__social .socials__list{
    justify-content: center;
  }
}
.reg-links {
  margin-top: 50px;
  font-size: 16px;
  text-align: center;
  a {
    margin: 0 5px;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}
/*====================================*/
/*================PROFILE=============*/
/*====================================*/
.profile {
  padding: {
    top: 50px;
    left: 60px;
    right: 15px;
  }
  @include breakpoint($md) {
    padding: {
      left: 30px;
    };
  }
  @include breakpoint($sm) {
    padding: {
      top: 15px;
      left: 20px;
      right: 20px;
    };
  }
  @include breakpoint($xs) {
    padding: {
      top: 15px;
      left: 25px;
      right: 25px;
    };
  }
  &__title {
    padding-bottom: 50px;
    font-family: "28 days", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
  }

  &__layout {
    display: flex;
    justify-content: space-between;
    @include breakpoint($sm) {
      flex-direction: column;
      max-width: 670px;
      margin: auto;
    }
    @include breakpoint($xs) {
      flex-direction: column;
      max-width: 570px;
      margin: auto;
    }
  }
    &--left {
      flex: 0 1 200px;
      @include breakpoint($sm) {
        margin-bottom: 30px;
      }
      @include breakpoint($xs) {
        margin-bottom: 30px;
      }

    }
    &--right {
      flex: 0 1 670px;
      @include breakpoint($md) {
        flex: 0 1 560px;
        .form__section {
          flex: 0 1 270px;
          max-width: 270px;
          &--submit {
            margin: auto;
          }
        }
      }
      @include breakpoint($xs) {
        flex: 0 1 560px;
        .form__section {
          flex: 0 1 270px;
          max-width: 270px;
          &--submit {
            margin: auto;
          }
        }
      }
    }
  &__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.avatar-wrapper{
  position: relative;
  height: 200px;
  width: 200px;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 1px 1px 15px -5px black;
  transition: all .3s ease;
  margin: auto;
  z-index: 0;
  &::after{
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    z-index: 0;
  }
  @include breakpoint($sm) {
    height: 240px;
    width: 240px;
  }
  @include breakpoint($xs) {
    height: 240px;
    width: 240px;
  }
  &:hover{
    cursor: pointer;
  }
  .profile-pic {
    height: 100%;
    width: 100%;
    transition: all .3s ease;
    position: absolute;
    z-index: 1;
    border-radius: 25px;
  }
  .upload-button {
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 24px;
    overflow: hidden;
    p {
      position: absolute;
      color: #ffffff;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 300;
      bottom: -31px;
      left: 0;
      right: 0;
      text-align: justify;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      background: rgba(0, 0, 0, 0.7);
      padding: 7px 20px;
      &::after {
        content: url(../img/master/camera-ico.png);
        position: absolute;
        right: 20px;
      }
    }
    &:hover p{
      bottom: 0;
    }
  }
}
.avatar__description {
  padding: {
    top: 5px;
  }
  text-align: center;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}
.account__status {
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 40px;
  color: #d3b666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;

  & span {
    font-weight: 400;
    color: #ffffff;
  }
}
/*====================================*/
/*==============ACCOUNTS==============*/
/*====================================*/
.accounts {
  padding: {
    top: 50px;
    left: 60px;
    right: 15px;
  }
  @include breakpoint($md) {
    padding-left: 30px;
  }
  @include breakpoint($sm) {
    padding: {
      top: 15px;
      left: 20px;
      right: 20px;
    };
  }
  @include breakpoint($xs) {
    padding: {
      top: 0;
      left: 25px;
      right: 25px;
    };
  }
  &__title {
    padding-bottom: 50px;
    font-family: "28 days", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
  }
  &__layout {
    padding: {
      bottom: 40px;
    };
  }
  &__buttons {
    text-align: center;
  }
}

/*====================================*/
/*================NEWS================*/
/*====================================*/
.news {
  padding: {
    bottom: 45px;
  }
  &__layout {
    display: flex;
    justify-content: space-between;
    @include breakpoint($sm) {
      flex-direction: column;
    }
    @include breakpoint($xs) {
      flex-direction: column;
    }
  }

  &--left {
    flex: 0 1 780px;
    margin: {
      top: 40px;
      left: 15px;
      right: 30px;
    }
    @include breakpoint($sm) {
      flex: 0 1 480px;
      margin: {
        top: 30px;
        left: 20px;
        right: 20px;
        bottom: 10px;
      }
    }
    @include breakpoint($xs) {
      flex: 0 1 480px;
      margin: {
        top: 30px;
        left: 20px;
        right: 20px;
        bottom: 10px;
      }
    }
  }

  &--right {
    flex: 0 1 360px;
    margin: {
      top: 10px;
      right: 15px;
      bottom: 10px;
    }
    @include breakpoint($sm) {
      margin: {
        left: 20px;
        right: 20px;
        bottom: 0;
      }
    }
    @include breakpoint($xs) {
      margin: {
        left: 20px;
        right: 20px;
        bottom: 0;
      }
    }
  }
  &__title {
    display: block;
    text-align: center;
    font: {
      family: "28 days", sans-serif;
      size: 30px;
      weight: 400;
    }
    text-transform: uppercase;
    padding: {
      top: 20px;
      bottom: 50px;
    };
  }
  &__list {
    margin: {
      bottom: 30px;
    }
    @include breakpoint($sm) {
      margin: {
        bottom: 0;
      }
    }
    @include breakpoint($xs) {
      margin: {
        bottom: 0;
      }
    }
  }

  &__list {
    li:not(:last-child) {
      padding: {
        bottom: 30px;
      };
    }
  }
  &__list p {
       text-align: justify;
       font-size: 16px;
       line-height: 1.4em;
       padding: {
         top: 15px;
         bottom: 15px;
       };
     }
  &__item__wrap {
    padding: {
      top: 20px;
    }
    & img {
      display: block;
      margin: {
        top: 0;
        left: auto;
        right: auto;
        bottom: 20px;
      }
    }
  }
  &__item__wrap p {
    text-align: justify;
    font: {
      size: 16px;
    };
    line-height: 1.4em;
    padding: {
      top: 15px;
      bottom: 25px;
    }
  }

}
.date {
  display: block;
  font: {
    family: Roboto;
    size: 16px;
    weight: 400;
  };
  margin: {
    top: 15px;
    bottom: 15px;
  };
}
.back-link {
  font: {
    family: "Roboto", sans-serif;
  }
  text-decoration: underline;
}
.back-link:hover {
  text-decoration: none;
}
/*====================================*/
/*================PAYMENTS============*/
/*====================================*/
.payments {

}

/*====================================*/
/*================RESTORE REQUEST=====*/
/*====================================*/
.restore {
  &__layout {
    min-height: 870px;
    padding: {
      top: 30px;
    };
  }
  &__title {
    display: block;
    text-align: center;
    font: {
      family: "28 days", sans-serif;
      size: 30px;
      weight: 400;
    }
    text-transform: uppercase;
    padding: {
      top: 20px;
      bottom: 50px;
    };
  }
}

/*====================================*/
/*================SIDEBAR=============*/
/*====================================*/
.sidebar {
  padding: {
    top: 120px;
    left: 20px;
  }
  @include breakpoint($sm) {
    padding: {
      top: 50px;
      right: 20px;
    };
  }
  @include breakpoint($xs) {
    padding: {
      top: 50px;
      right: 20px;
      left: 25px;
    };
  }
  &__title {
    padding: {
      bottom: 40px;
    }
    @include breakpoint($sm) {
      padding-bottom: 15px;
    }
    @include breakpoint($xs) {
      padding-bottom: 15px;
    }
    text-shadow: 0 2px 1px #000000;
    color: #d7c59d;
    font-family: "Bookman Old Style";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
  }
  &__list {
    @include breakpoint($sm) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    @include breakpoint($xs) {
      display: block;
    }
    &_item {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 36px;
      font-weight: 400;
      position: relative;
      @include breakpoint($sm) {
        display: inline-block;
        padding-right: 15px;
        &::before {
          content: "\2022";
          left: -18px;
          position: absolute;
        }
        &:first-child::before, &:last-child::before {
          content: none;
        }
      }
      @include breakpoint($xs) {
        display: inline-block;
        padding-right: 23px;

        &::before {
          content: "\2022";
          left: -18px;
          position: absolute;
        }
        &:first-child::before {
          content: none;
        }
        &:nth-child(6)::before {
          content: none;
        }

      }
      &_active {
        color: #cbc395;
        text-decoration: underline;
      }
    }

    a:hover, a:active {
      color: #cbc395;
      text-decoration: underline;
    }
  }
}
/*====================================*/
/*===============MODALS===============*/
/*====================================*/

.modals {
  &__layout {
    padding: 50px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  & li {
    margin-bottom: 20px;
    text-transform: uppercase;
    & .button--sp1 {
      width: 420px;
    }
  }
  }
}
:lang(en) {
  .sidebar__list_item {
    padding-right: 30px;
  }
}

.clear {
  &::before {
    content: '';
    position: absolute;
    top: 55px;
    left: 31px;
    width: 1px;
    height: 255px;
    background: #999999;
    display: block;
    @include breakpoint($sm) {
      left: 31px;
      width: 2px;
      height: 345px;
    }
    @include breakpoint($xs) {
      content: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 55px;
    right: 31px;
    width: 1px;
    height: 255px;
    background: #999999;
    display: block;
    @include breakpoint($sm) {
      right: 28px;
      width: 2px;
      height: 345px;
    }
    @include breakpoint($xs) {
      content: none;
    }
  }
}