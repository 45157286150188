.ui-tabs {
  padding: 0;

  .ui-tabs-nav {
    padding: 0;
    margin-bottom: 35px;
    line-height: 1;

    li {
      margin: 0 30px 0 0;
      cursor: pointer;

      &.ui-state-active .ui-tabs-anchor {
        background-position: left bottom;
      }

      &:last-of-type {
        @include breakpoint($sm + "," + $xs){
          margin-right: 0;
        }
      }
    }

    .ui-tabs-anchor {
      display: flex;
      align-items: center;
      padding: 0;
      font: 16px $font;
      outline: none;
      -webkit-font-smoothing: antialiased;
      height: 34px;
      background: url("../img/master/tab-ico.png") left top no-repeat;
      padding-left: 50px;


      label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }

      span {
        display: inline-block;
        cursor: pointer;

      }
    }
  }

  .ui-tabs-panel {
    padding: 0;
  }
}