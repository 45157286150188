.footer {
  height: 229px;
  background: url(../img/master/footer_bg.png) center center no-repeat;
  border-top: 1px solid #a5a39c;
  overflow: hidden;
  @include breakpoint($xs) {
    height: 257px;
    background: url(../img/master/footer_mobile_bg.jpg) center center no-repeat;
  }

  .container {
    padding: 0;
    border: {
      left: 1px solid #787672;
      right: 1px solid #787672;
    };
    position: relative;

    &::before, &::after {
      content: "";
      display: block;
      position: absolute;
      height: calc(100% + 3px);
      width: 100%;
      background: rgba(#000, .5);
      top: -3px;
    }

    &::before {
      right: -100%;
    }

    &::after {
      left: -100%;
    }
  }

  &__layout {
    overflow: hidden;
  }

  &__payments {
    margin: {
      top: 30px;
    };

    &__list {
      display: flex;
      justify-content: center;
    }

    &__item {
      flex: 0 1 93px;
      width: 93px;
      height: 48px;
      mix-blend-mode: overlay;

      &:last-of-type {
        margin: {
          right: 0;
        };
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  &__menu {
    margin: {
      top: 11px;
    };
    @include breakpoint($xs) {
      padding: {
        left: 50px;
        right: 50px;
      };
    }
    &__list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }

    &__item {
      margin: {
        right: 45px;
      };

      @include breakpoint($sm) {
        margin: {
          right: 15px;
        };
        &::after {
          left: calc(100% + 5px);
        }
      }
      @include breakpoint($xs) {
        margin: {
          right: 15px;
          bottom: 7px;
        };
        &::after {
          left: calc(100% + 5px);
        }
      }
        position: relative;

      &::after {
        content: "•";
        color: #fff;
        font: bold 16px $fontBold;
        mix-blend-mode: overlay;
        position: absolute;
        top: 50%;
        left: calc(100% + 20px);
        transform: translateY(-50%);
      }

      &:last-of-type {
        margin: {
          right: 0;
        };
      }

      &:last-of-type::after {
        display: none;
      }
    }

    &__link {
      color: #fff;
      font: bold 16px $fontBold;
      mix-blend-mode: overlay;
      text-shadow: 0 2px 3px black;
      transition: all .3s;

      &:hover {
        text-shadow: 0 1px 2px black;;
        transition: all .3s;
      }
    }
  }

  &__socials {
    margin: {
      top: 17px;
    };
    @include breakpoint($xs) {
      margin-top: 12px;
    }

    &__list {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }

    &__item {
      margin: {
        right: 7px;
      };
      width: 33px;
      height: 34px;
      background: url(../img/master/footer_socials_bg.png) no-repeat;
      mix-blend-mode: overlay;
      
      &:nth-of-type(2){
        background-position: -40px 0;
      }

      &:nth-of-type(3){
        background-position: -81px 0;
      }

      &:nth-of-type(4){
        background-position: -122px 0;
      }

      &:nth-of-type(5){
        background-position: -161px 0;
      }

      &:nth-of-type(6){
        background-position: -201px 0;
      }

      &:last-of-type {
        background-position: right 0;
        margin: {
          right: 0;
        };
      }
    }

    &__link {
      display: block;
      height: 100%;
    }
  }

  &__ages {
    position: absolute;
    right: 238px;
    top: 128px;
    @include breakpoint($md) {
      right: 160px;
    }
    @include breakpoint($sm) {
      right: 50px;
    }
    @include breakpoint($xs) {
      right: 39px;
      top: 155px;
    }
  }

  &__copyright {
    margin: {
      top: 30px;
    };
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    mix-blend-mode: overlay;
    color: #fff;
    text-shadow: 0 2px 3px black;
    line-height: 1;
  }
}