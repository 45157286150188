.pager {
  padding: 30px 0;
  font-size: 16px;
  font-weight: 700;
  @include breakpoint($xs) {
    padding: 20px 0;
  }
  & ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item {
    display: inline-block;
    margin: 0 6px;
    position: relative;
    border: solid 2px;
    border-radius: 100%;
    color: #9f8e6e;
    width: 27px;
    height: 27px;
    line-height: 28px;
    opacity: 0.5;
    text-align: center;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.5), 0 2px 1px rgba(0, 0, 0, 0.5);
    transition: all .4s;
    cursor: pointer;
    &_active {
      border-width: 1px;
      font-weight: 400;
    }
    &:hover {
      opacity: 1;
      transition: all .4s;
    }
    & a {
      display: inline-block;
      position: relative;
      width: 27px;
      height: 27px;
      line-height: 28px;
      text-align: center;
      transition: all .4s;
    }
  }
  & .pag_nav {
    font-size: 20px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-family: 'FontAwesome';
    width: 40px;
    height: 26px;
    line-height: 28px;
    text-align: center;
    transition: all .4s;
    background-color: #fff;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fff, endColorstr=#d1bd8f);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
    background-image: -webkit-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
    background-image: -ms-linear-gradient(top, #fff 0%, #d1bd8f 40%, #d1bd8f 61%, #8d7133 84%);
    background-image: -webkit-gradient(linear, right top, right bottom, color-stop(0%, #fff), color-stop(40%, #d1bd8f), color-stop(61%, #d1bd8f), color-stop(84%, #8d7133));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.3);
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}
.hidden {
  display: none;
}