.socials {
  &__list {
    display: flex;
    flex-flow: row nowrap;
  }

  &__item {
    width: 42px;
    height: 43px;
    flex: 0 1 42px;
    background: url(../img/master/socials_bg.png) no-repeat;
    margin-right: 3px;
    border-radius: 35px;
    overflow: hidden;

    $iconH: -70px;

    &:last-of-type {
      margin-right: 0;
    }

    &[data-uloginbutton="vkontakte"]{
      background-position: left top;

      &:hover {
        background-position: -151px 0;
      }
      
      &:focus, &:active {
        background-position: right 0;
      }
    }

    &[data-uloginbutton="facebook"]{
      background-position: left $iconH;

      &:hover {
        background-position: -150px $iconH;
      }

      &:focus, &:active {
        background-position: right $iconH;
      }
    }
    
    &[data-uloginbutton="twitter"]{
      background-position: left $iconH*2;

      &:hover {
        background-position: -150px $iconH*2;
      }

      &:focus, &:active {
        background-position: right $iconH*2;
      }
    }
    
    &[data-uloginbutton="odnoklassniki"]{
      background-position: left $iconH*3;

      &:hover {
        background-position: -150px $iconH*3;
      }

      &:focus, &:active {
        background-position: right $iconH*3;
      }
    }
    
    &[data-uloginbutton="googleplus"]{
      background-position: left $iconH*4;

      &:hover {
        background-position: -150px $iconH*4;
      }

      &:focus, &:active {
        background-position: right $iconH*4;
      }
    }
    
    &[data-uloginbutton="yandex"]{
      background-position: left $iconH*5;

      &:hover {
        background-position: -150px $iconH*5;
      }

      &:focus, &:active {
        background-position: right $iconH*5;
      }
    }

    &[data-uloginbutton="mailru"]{
      background-position: left $iconH*6;
      
      &:hover {
        background-position: -150px $iconH*6;
      }
      
      &:focus, &:active {
        background-position: right $iconH*6;
      }
    }
  }

  &__link {
    display: block;
    height: 100%;
  }


}

