.table {
  width: 100%;
  margin-bottom: 30px;
  &--transactions tbody tr td {
    padding: 35px 20px;
  }
  @include breakpoint($sm) {
    margin-bottom: 0;
  &--accounts {
    margin-bottom: 30px;
  }
  }
  @include breakpoint($xs) {
    margin-bottom: 0;
  }
    thead {
      @include breakpoint($xs){
        display: none;
      }

      tr {


        @include breakpoint($xs){
          display: block;
          height: auto;
        }

        th {

        }
      }
    }

&__bonuses {
  & a {
    color: #fec606;
    font-size: 14px;
    font-weight: 300;
    text-decoration: underline;
  }
  & a:hover {
    color: #25b711;
    text-decoration: none;
  }
}
    tbody {
      @include breakpoint($xs){
        display: block;
      }

      tr {


        td {
            position: relative;

          img {
            margin-right: 10px;
          }

          @include breakpoint($xs){
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            min-height: 70px;
            width: 50%;
            padding-left: 50%;
            line-height: 1;
          }

          &::before {
            @include breakpoint($xs){
              content: attr(data-label);
              display: flex;
              justify-content: center;
              align-items: center;
              width: 50%;
              height: 100%;
              text-align: center;
              position: absolute;
              top: 0;
              left: 0;
              color: #d3b666;
              text-transform: uppercase;
            }
          }
        }

        @include breakpoint($xs){
          height: auto;
          display: block;
          overflow: hidden;
          margin-bottom: 35px;

          background: url(../img/master/tab_mobile_bg.png) top center no-repeat;
          position: relative;
          background-size: 100% 100%;
          position: relative;

        }
      }
    }
  &--accounts {
    @include  breakpoint($xs) {
      tbody tr, tbody tr:nth-child(2n) {
        background: url(../img/master/accounts_bg.png) top center no-repeat;
        background-size: 100% 100%;
      }
    }
    }
  &--transactions {
    @include  breakpoint($xs) {
      thead {
        display: table-header-group;
      }
      thead tr {
        display: table-row;
      }
      tbody {
        display: table-row-group;
        background: rgba(0, 0, 0, 0.1);
      }
      tbody tr {
        display: table-row;
        background: none;
      }
      tbody tr:nth-child(2n) {
        background: rgba(0, 0, 0, 0.12);
      }
      tbody tr td {
        display: table-cell;
        padding-left: 20px;
      }
      tbody tr td:nth-of-type(even) {
        background: none;
      }
      tbody tr td::before {
        content: none;
      }
    }
  }
  }


  table {
    width: 100%;
    border-collapse: collapse;
    background: $tableBG;
    text-align: center;
    position: relative;
    @include breakpoint($xs) {
      background: none;
    }

    thead {
      background: url(../img/master/thead-bg.png);
      background-color: rgba(0, 0, 0, 0.75);
    }
    thead tr {
      border: solid 1px #b7a98e;

    }
    thead:before {
      content: url(../img/master/left-angle.png);
      position: absolute;
      left: -2px;
      top: 0px;
      display: block;
    }
    thead:after {
      content: url(../img/master/right-angle.png);
      position: absolute;
      right: -2px;
      top: 0px;
      display: block;
    }

    thead th {
      font: {
        family: "Roboto", sans-serif;
        size: 16px;
        weight: 400;
      }
      text-transform: uppercase;
      color: #d3b666;
      text-align:center;
      padding:15px;
      position: relative;
    }

    thead th:before {
      content: '';
      width: 1px;
      background: #b7a98e;
      height: 36px;
      position: absolute;
      left: -1px;
      top: 7px;
      box-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    }

  tbody tr td {
    font: {
      family: "Roboto", sans-serif;
      size: 16px;
      weight: 400;
    }
    color:#ffffff;
    padding: 20px;
    @include breakpoint($sm) {
      padding: 20px 15px;
    }
    @include breakpoint($xs) {
      padding: 0;

      &:nth-of-type(even) {
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }

  tbody tr:nth-child(2n) {
    background:rgba(0, 0, 0, 0.12);
    @include breakpoint($xs) {
      background: url(../img/master/tab_mobile_bg.png) top center no-repeat;
      position: relative;
      background-size: 100% 100%;    }
  }

  tbody tr:last-child td {
    border-bottom:none;
    &:first-child {
      border-bottom-left-radius:(5px);
    }
    &:last-child {
       border-bottom-right-radius:(5px);
    }
  }

  tbody:hover > tr:hover td {
    @include breakpoint($xs) {
      opacity: 1;
    }
  }

  .status {


    &_success {
      color: $statusSuccess;
    }

    &_pending {
      color: $statusPending;
    }

    &_canceled {
      color: $statusCanceled;
    }

    &_get {
      text-decoration: underline;
      color: $statusGet;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
table.table__bonuses tbody tr td {
  font: {
    weight: 300;
  };
}
table.table__bonuses th:first-child {
  width: 240px;
  text-align: left;
  padding-left: 30px;
  @include breakpoint($md) {
    width: 200px;
  }
  @include breakpoint($sm) {
    width: 165px;
  }
}
table.table__bonuses td:first-child {
  text-align: left;
  padding: {
    left: 30px;
    right: 70px;
  };
  @include breakpoint($md) {
    padding-right: 30px;
  }
  @include breakpoint($sm) {
    padding-right: 15px;
  }
  @include breakpoint($xs) {
    text-align: center;
    padding-left: 50%;
    padding-right: 0;
  }
}

table.table--accounts td {
  height: 70px;
  padding: 0;
}



.documents {
  margin-bottom: 30px;
  & a {
    color: #ffffff;
    text-transform: none;
  }
  & table {
    background: transparent;
    position: relative;
    & thead  {
      background: transparent;
      & tr {
        height: 70px;
        & th:first-child {
          background: url(../img/master/table-top-left-bg.png) left top no-repeat;
        }
        & th:not(first-child), & th:last-child {
          background: url(../img/master/table-top-center-bg.png) left top repeat-x;
        }
        & th:last-child {
          background: url(../img/master/table-top-right-bg.png) right top no-repeat;
        }
      }
      &::before {
        content: none;
    }
      &::after {
        content: none;
      }
      & th::before {
        content: none;
      }
      & tr {
        border: none;
        z-index: 0;
      }
    }
    & tbody {
      background: transparent;
      & tr {
        & td {
          vertical-align: top;
          @include breakpoint($xs) {
            padding: 20px 0;
          }
        }
        & td:first-child {
          background: url(../img/master/table-left-bg.png) left top repeat-y;
        }
        & td:not(first-child), & td:last-child {
          background: url(../img/master/table-center-bg.png) left top repeat;
        }
        & td:last-child {
          background: url(../img/master/table-right-bg.png) right top repeat-y;
        }
      }
      & tr:last-child {
        & td:first-child {
          background: url(../img/master/table-bottom-left-bg.png) left bottom no-repeat;
          padding-bottom: 20px;
        }
        & td:not(first-child), & td:last-child {
          background: url(../img/master/table-bottom-center-bg.png) left bottom repeat-x;
        }
        & td:last-child {
          background: url(../img/master/table-bottom-right-bg.png) right bottom no-repeat;
        }
      }
      & tr:nth-child(2n) {
        background: transparent;
      }
    }

  }
}
.table--transactions tbody tr td img {
  min-width: 160px;
}
.table_docs {
  & tr td {
    min-width: 33%;
    padding: 10px;
    & a {
      display: block;
      width: 100px;
      max-height: 90px;
      overflow: hidden;
      margin: auto;
    }
  }

}