.header {
  height: 168px;
  background: url(../img/master/header_bg.jpg) center top no-repeat;
  .expand {
    display: none;

    @include breakpoint($xs){
      display: block;
      width: 54px;
      height: 54px;
      position: absolute;
      right: 25px;
      top: 93px;
      text-align: center;
      font-family: 'FontAwesome';
      text-shadow: 0 0 19px rgba(0, 0, 0, 0.6), 0 2px 1px #000000;
      color: #d7c59d;
      font-size: 26px;
      background: url(../img/master/burger_bg.png) center bottom no-repeat;
      z-index: 10;
    }

    &.expanded {
      filter: brightness(140%);
      background: url(../img/master/burger_active_bg.png) center bottom no-repeat;
    }
  }

  @include breakpoint($md) {
    background: url(../img/master/header_tablet_bg.jpg) center top no-repeat;
  }
  @include breakpoint($sm) {
    background: url(../img/master/header_tablet_portrait_bg.jpg) center top no-repeat;
  }
  @include breakpoint($xs) {
    background: url(../img/master/header_mobile_bg.jpg) center top no-repeat;
  }
  .container {
    position: relative;
    padding: {
      left: 0;
      right: 0;
    }
  }

  &__layout {
    display: flex;
    justify-content: space-between;
    @include breakpoint($md) {
      padding-right: 15px;
    }
    @include breakpoint($sm) {
      padding-right: 25px;
    }
    @include breakpoint($xs) {
      padding-right: 25px;
    }
  }

  &__logo {

    margin: {
      top: -16px;
      left: 5px;
    }
    @include breakpoint($xs) {
      margin: {
        top: -9px;
        left: 14px;
      }
    }

    &__link img {
      width: 185px;
      @include breakpoint($xs) {
        width: 170px;
      }
    }

  }

  &--top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: {
      top: 12px;
      bottom: 25px;
    }
  ;

    &--left {
      display: flex;
      justify-content: flex-start;
      flex-flow: row nowrap;
      align-items: center;
      z-index: 2;
    }

    &--right {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  &--register {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 356px;
    height: 232px;
    top: -20px;
    right: 30px;
    z-index: 200;

    @include breakpoint($md) {
      width: 234px;
      height: 110px;
      right: 18px;
    }
    @include breakpoint($sm) {
      width: 234px;
      height: 110px;
      right: 18px;
    }
    @include breakpoint($xs) {
      width: 234px;
      height: 110px;
      right: 118px;
      z-index: 111;
    }
    &::before, &::after {
      content: "";
      display: block;
      position: absolute;
      width: 52px;
      height: 166px;
      top: -1px;
      @include breakpoint($md) {
        width: 28px;
        height: 74px;
      }
      @include breakpoint($sm) {
        width: 28px;
        height: 74px;
      }

      }

    &::before {
      background: url(../img/master/header_chain_left.png) no-repeat;
      left: 0;
      @include breakpoint($md) {
        background: url(../img/master/header_chain_left_tablet_portrait.png) no-repeat;
      }
      @include breakpoint($sm) {
        background: url(../img/master/header_chain_left_tablet_portrait.png) no-repeat;
      }

      }

    &::after {
      background: url(../img/master/header_chain_right.png) no-repeat;
      right: 0;
      @include breakpoint($md) {
        background: url(../img/master/header_chain_right_tablet_portrait.png) no-repeat;
      }
      @include breakpoint($sm) {
        background: url(../img/master/header_chain_right_tablet_portrait.png) no-repeat;
      }
    }

    a {
      width: 294px;
      height: 83px;
      background: url(../img/master/btn_register_bg.png) no-repeat;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.9);
      font-size: 28px;
      font-weight: 700;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.8);
      letter-spacing: 2.6px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint($md) {
        width: 222px;
        height: 52px;
        font-size: 18px;
        background: url(../img/master/btn_registe_tablet_partrait_bg.png) no-repeat;
      }
      @include breakpoint($sm) {
        width: 222px;
        height: 52px;
        font-size: 18px;
        background: url(../img/master/btn_registe_tablet_partrait_bg.png) no-repeat;
      }
      &:hover {
        background-position: left -1px center;
      }

      &:active {
        background-position: left bottom;
      }
    }
  }

  &__socials {
    height: 57px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 55px;
    position: relative;
    background: url(../img/master/some-panel/some_panel_left_bg.png) left top no-repeat,
    url(../img/master/some-panel/some_panel_right_bg.png) right top no-repeat;
    image-rendering: optimize-speed;
    padding: {
      right: 7px;
      left: 25px;
    }
  ;
    margin: {
      right: 12px;
    }
  ;
    z-index: 0;

    &::before {
      content: "";
      background: url(../img/master/some-panel/some_panel_bg.png) repeat-x;
      position: absolute;
      display: block;
      width: calc(100% - 56px);
      left: 28px;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
    @include breakpoint($sm) {
      padding-left: 7px;
      margin-right: 3px;
    }
    @include breakpoint($xs) {
      padding-left: 7px;
      margin-right: 3px;
    }
    p {
      color: #999999;
      font: 16px $font;
      margin: {
        right: 21px;
        top: -3px;
      }
    ;
      @include breakpoint($sm) {
        display: none;
      }
      @include breakpoint($xs) {
        display: none;
      }
    }

    .socials__list {
      margin: {
        top: -2px;
      }
    ;
    }
  }
  &__user {

    &__menu {
      position: relative;
      z-index: 1;
      width: 77px;
      height: 56px;
      background: rgba(0, 0, 0, 0.5);
      border-top: 4px ridge #915e3b;
      border-bottom: 4px groove #915e3b;
      border-radius: 30px;
      border-right: 4px groove rgb(133, 77, 43);
      border-left: 4px ridge #854d2b;
      box-shadow: 0 1px 2px #000;
      box-sizing: border-box;
      cursor: pointer;


      &:active {
        background-position: left bottom, right bottom;

        &::before {
          background-position: right 0px center;
        }
      }

      &__toggler {
        height: 100%;
        width: 100%;
        &::before {
          content: "";
          display: block;
          height: 26px;
          width: 21px;
          margin: {
            left: 15px;
          };
          background: url(../img/master/user_bg.png) center no-repeat;
          transform: translateY(50%);
        }
        &::after {
          content: '';
          font-family: 'FontAwesome';
          font-size: 20px;
          color: #cbc395;
          text-shadow: 0 1px 1px #000000;
          width: 12px;
          position: absolute;
          top:16px;
          right: 15px;
          transition: transform .2s;
        }
        &.rotated::after {
          transform: rotate(180deg);
          transition: transform .2s;
        }
        }
       &__list {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 400;
        width: 177px;
        background: url(../img/master/user_menu/user_menu_list_top_bg.png) center top no-repeat,
        url(../img/master/user_menu/user_menu_list_bottom_bg.png) center bottom no-repeat;
        padding: {
          top: 26px;
          bottom: 15px;
        };
        margin: {
          top: 64px;
        };
        box-sizing: border-box;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: calc(100% - 66px);
          background: url(../img/master/user_menu/user_menu_list_bg.png) repeat-y;
          position: absolute;
          z-index: -1;
          top: 37px;
        }
      }

      &__item {
        height: 38px;
        margin: {
          left: 15px;
          right: 18px;
        };
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: {
          bottom: solid 1px rgba(149, 149, 149, 0.5);
        };

        a {
          color: #cbc395;
          font: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: {
            left: 3px;
          };

          &::before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 14px;
            margin-right: 10px;
            background-repeat: no-repeat;
          }
        }
        a:hover {
          color: #ffffff;
        }
        &:first-of-type {
          justify-content: center;
          font-weight: 700;
        }
        &:last-of-type {
          border: none;
        }
      }

      .prof::before {
        background: url("../img/master/user_menu/ico_profile.png");
      }

      .wal::before {
        background: url("../img/master/user_menu/ico_wallet.png");
      }

      .bon::before {
        background: url("../img/master/user_menu/ico_bonus.png");
      }

      .exi::before {
        background: url("../img/master/user_menu/ico_exit.png");
      }
    }

  }
  &__currency  {
    margin: {
      left: 15px;
    }

    @include breakpoint($sm){
      margin-left: 5px;
    }
    @include breakpoint($xs){
      margin-left: 5px;
    }
  }
  &__currency .ui-selectmenu-button.ui-button {
    display: block;
    height: 54px;
    min-width: 255px;
    padding-left: 40px;
    line-height: 36px;
    z-index: 1;
    @include breakpoint($sm) {
      min-width: 202px;
      padding: {
        left: 10px;
      };
    }
    @include breakpoint($xs) {
      min-width: 202px;
      padding: {
        left: 10px;
      }
    }
  }

  &__currency .ui-selectmenu-text {
    font: {
      size: 18px;
      style: normal;
    };
    color: #cbc395;
    @include breakpoint($sm) {
      margin-right: 0;
    }
  }
  &__currency .ui-selectmenu-text:after {
    content: '';
    font-family: 'FontAwesome';
    font-size: 20px;
    font-style: normal;
    color: #cbc395;
    position: absolute;
    right: 33px;
    @include breakpoint($sm) {
      right: 10px;
    }
    @include breakpoint($xs) {
      right: 10px;
    }
  }
  &__stars {
    margin-left: 15px;
    position: relative;
    user-select: none;
    @include breakpoint($sm) {
      margin-left: 5px;
    }

    > a {
      display: block;
      height: 54px;
      width: 54px;
      background: rgba(0, 0, 0, 0.5);
      border-top: 4px ridge #915e3b;
      border-bottom: 4px groove #915e3b;
      border-radius: 30px;
      border-right: 4px groove #854d2b;
      border-left: 4px ridge #854d2b;
      box-shadow: 0px 1px 2px #000;
      box-sizing: border-box;
      &::before {
        content: "";
        display: block;
        height: 27px;
        width: 29px;
        margin: auto;
        background: url(../img/master/stars_bg.png) left no-repeat;
        transform: translateY(40%);
      }

      &:hover {
        background-position: left bottom, right bottom;

        &::before {
          background-position: -157px center;
        }
      }

      &:active {
        background-position: left bottom, right bottom;

        &::before {
          background-position: right 0px center;
        }
      }
    }
    &__counter {
      position: absolute;
      top: 8px;
      right: 12px;
      font-size: 10px;
    }
  }
  &__login {
    @include breakpoint($xs) {
      padding-left: 5px;
    }
  }
  .pressed > a::before {
    background-position: right 0px center;
  }
  &__logout {
    text-shadow: 0 2px 1px #000000;
    font-size: 16px;
    text-decoration: underline;
    margin-left: 75px;
  }
  &__logout:hover{
    text-decoration: none;
  }
  &__langs {
    position: relative;
    margin: {
      left: 10px;
    };
    outline: none;
    @include breakpoint($sm) {
      margin-left: 5px;
    }


    &__toggler {
      height: 54px;
      min-width: 97px;
      color: #cbc395;
      text-transform: uppercase;
      font: 18px $fontLight;
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
      text-align: right;
      padding: {
        right: 20px;
      };
      cursor: pointer;
      z-index: 2;
      user-select: none;
      line-height: 48px;
      background: rgba(0, 0, 0, 0.5);
      border-top: 4px ridge #915e3b;
      border-bottom: 4px groove #915e3b;
      border-radius: 30px;
      border-right: 4px groove #854d2b;
      border-left: 4px ridge #854d2b;
      box-shadow: 0px 1px 2px #000;
      box-sizing: border-box;

      @include breakpoint($sm) {
        min-width: 54px;
        padding-right: 3px;
      }

      span {
        display: inline-block;
        vertical-align: top;
        margin: {
          left: 4px;
        };
        @include breakpoint($sm) {
          display: none;
        }
      }

      &::before {
        content: "";
        display: inline-block;
        width: 42px;
        height: 43px;
        border-radius: 50px;
        margin: {
          top: 4px;
          left: 3px;
        };
        background: url(../img/master/langs/flags_bg.png) no-repeat;
      }
    }

    &__icon {
      display: block;
      position: absolute;
      background: url(../img/master/langs/langs_arrow_bg.png) no-repeat;
      width: 11px;
      height: 7px;
      right: 8px;
      top: 48%;
      text-indent: -9999px;
      overflow: hidden;
      transform: translateY(-50%);
      transition: transform .2s;
    }

    &__list {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 400;
      width: 127px;
      background: url(../img/master/langs/langs_list_top_bg.png) center top no-repeat,
                url(../img/master/langs/langs_list_bottom_bg.png) center bottom no-repeat;
      padding: {
        top: 26px;
        bottom: 15px;
      };
      margin: {
        top: 64px;
      };
      box-sizing: border-box;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: calc(100% - 66px);
        background: url(../img/master/langs/langs_list_bg.png) repeat-y;
        position: absolute;
        z-index: -1;
        top: 37px;
      }

      &:focus {
        display: block;
      }
    }

    &__item {
      height: 43px;
      margin: {
        bottom: 17px;
      };
      display: flex;
      align-items: center;

      a {
        color: #cbc395;
        text-transform: uppercase;
        font: 18px $fontLight;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: {
          left: 22px;
        };
        height: 100%;

        &::before {
          content: "";
          display: inline-block;
          width: 42px;
          height: 43px;
          margin: {
            right: 10px;
          };
          border-radius: 50px;
          background: url(../img/master/langs/flags_bg.png) no-repeat;
        }
      }

      &:last-of-type {
        margin: {
          bottom: 0;
        };
      }
    }

    .ru::before, .ru a::before {
      background-position: center bottom;
    }
    
    .eng::before, .eng a::before {
      background-position: center top 1px;
    }

    .ita::before, .ita a::before {
      background-position: center -59px;
    }

    .ces::before, .ces a::before {
      background-position: center -118px;
    }

    .jpn::before, .jpn a::before {
      background-position: center -178px;
    }

    .por::before, .por a::before {
      background-position: center -238px;
    }

    .swe::before, .swe a::before {
      background-position: center -298px;
    }

    .ron::before, .ron a::before {
      background-position: center -358px;
    }

    .de::before, .de a::before {
      background-position: center -418px;
    }

    .bul::before, .bul a::before {
      background-position: center -478px;
    }

    .spa::before, .spa a::before {
      background-position: center -538px;
    }

    .nor::before, .nor a::before {
      background-position: center -598px;
    }

    .ara::before, .ara a::before {
      background-position: center -658px;
    }

    .pol::before, .pol a::before {
      background-position: center -718px;
    }

    &:focus &__list {
      display: block;
    }
  }

  &__menu {
    &__list {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    &__item {
      height: 54px;
      width: 195px;
      box-sizing: border-box;
      background: url("../img/master/btn_bg.png") center top no-repeat;
      position: relative;
      @include breakpoint($md) {
        width: 163px;
        background: url("../img/master/btn_tablet_bg.png") center top no-repeat;
      }
      @include breakpoint($sm) {
        width: 110px;
        background: url("../img/master/btn_tablet_portrait_bg.png") center top no-repeat;
      }
      ul {
        visibility: hidden;
        opacity: 0;
        margin: {
          top: -5px;
        };
        position: absolute;
        width: 100%;
        z-index: 1000;
        transition: all .3s;

        li {
          height: 52px;
          background: url(../img/master/submenu_bg.png) no-repeat;
          box-sizing: border-box;
          text-align: center;
          a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cbc395;
            font: 17px $font;
            @include breakpoint($sm) {
              font-size: 15px;
            }
          }
        }
      }

      &:hover {
        background-position: center center;

        & .header__menu__link {
          color: #dbdad9;
          background: none;
          -webkit-text-fill-color: #dbdad9;
        }

        ul {
          visibility: visible;
          opacity: 1;
          margin: {
            top: 0;
          };

          &:hover {
            display: block;
          }
        }
      }

      &:active, &:visited, &_active {
        background-position: center bottom;
      }
    }

    &__link {
      font: bold 20px $fontBookmanBold;
      color: #d7c59d;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 1);

      @include breakpoint($sm) {
        font-size: 18px;
      }
    }
  }

  &--mobile {
    display: none;
    width: 248px;
    height: calc(100% - 80px);
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    right: 0;
    top: 80px;
    z-index: 4;

    .header__menu {
      margin-top: 20px;
      z-index: 2;
      &__list {
        flex-direction: column;
        align-items: center;
      }
    }
    .header__langs {
      margin-left: 15px;
    }
    .header__langs__toggler {
      min-width: 54px;
      padding-right: 2px;
    }
    .header__langs__toggler span {
      display: none;
    }
    .mobile-menu-wrapper {
      display: flex;
      margin: {
        top: 12px;
        left: 15px;
        right: 90px;
      };
      justify-content: flex-end;
    }
  }

}
.header__user__menu__item:first-of-type span {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rotated .header__langs__icon {
  transform: rotate(-180deg);
  transition: transform .2s;
}