@font-face {
  src: url(../fonts/Roboto-Regular.ttf);
  font-family: "Roboto";
}

@font-face {
  src: url(../fonts/Roboto-Black.ttf);
  font-family: "RobotoBlack";
}

@font-face {
  src: url(../fonts/Roboto-Bold.ttf);
  font-family: "RobotoBold";
}

@font-face {
  src: url(../fonts/Roboto-Light.ttf);
  font-family: "RobotoLight";
}

@font-face {
  src: url(../fonts/Roboto-Medium.ttf);
  font-family: "RobotoMedium";
}

@font-face {
  src: url(../fonts/Roboto-Thin.ttf);
  font-family: "RobotoThin";
}

@font-face {
  src: url(../fonts/Bookman-Bold.ttf);
  font-family: "BookmanBold";
}

@font-face {
  src: url(../fonts/AeroMaticsBold.ttf);
  font-family: "AeroMaticsBold";
}

@font-face {
  src: url(../fonts/fontawesome-webfont.ttf);
  font-family: "FontAwesome";
}
@font-face {
  src: url(../fonts/28-days.ttf);
  font-family: "28 Days";
}

$font : "Roboto", sans-serif;
  $fontBlack : "RobotoBlack", sans-serif;
  $fontBold : "RobotoBold", sans-serif;
  $fontLight : "RobotoLight", sans-serif;
  $fontMedium : "RobotoMedium", sans-serif;
  $fontThin: "RobotoThin", sans-serif;

  $fontBookmanBold: "BookmanBold", sans-serif;
  $fontAeroBold: "AeroMaticsBold", sans-serif;
  $fontDays: "28 Days", sans-serif;
  $baseFontSize : 14px;

  html {
    font-size: $baseFontSize;
    font-family: $font;
  }
h1 {
  font: 30px $fontDays;
  text-align: center;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 50px;
}
  h2 {
    display: block;
    text-shadow: 0 2px 1px #000000;
    color: #d7c59d;
    font: {
      family: "Bookman Old Style";
      size: 20px;
      weight: 700;
    };
  }
