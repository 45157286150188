.ui-selectmenu-button.ui-button {
  height: 58px;
  width: 100%;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #ffffff;
  font: 16px "Roboto", sans-serif;
  padding-left: 22px;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-top: 4px ridge #915e3b;
  border-bottom: 4px groove #915e3b;
  border-radius: 30px;
  border-right: 4px groove #854d2b;
  border-left: 4px ridge #854d2b;
  box-shadow: 0px 1px 2px #000;
  &.ui-selectmenu-button-open {
    border-radius: 30px 30px 0 0;
    border-bottom: none;
  }
}

.ui-selectmenu-icon.ui-icon {

}

.ui-selectmenu-text {

}

.ui-selectmenu-button.ui-selectmenu-button-open {

}

.ui-selectmenu-button.ui-selectmenu-button-open .ui-icon {
  transform: rotate(180deg);
  transition: transform .2s;
}

.ui-menu .ui-state-focus, .ui-menu .ui-state-active {
  margin: 0;
}

.ui-menu .ui-menu-item-wrapper {
  padding: 11px 0;
}

.ui-menu .ui-menu-item {
  box-sizing: border-box;
  padding: 0;
}

.ui-menu .ui-menu-item:not(:last-child) {
  border-bottom: solid 1px rgba(149, 149, 149, 0.5);
}
.ui-menu .ui-menu-item:hover {
  color: #ffffff;
}
.ui-selectmenu-menu .ui-menu {

}

.ui-selectmenu-open .ui-menu {
    box-sizing: border-box;
    max-height: 185px;
    padding: {
      top: 0;
      right: 20px;
      left:20px;
      bottom: 10px;
    };
    color: #cbc395;
    font-size: 16px;
  top: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  border-top: none;
  border-bottom: 4px groove #915e3b;
  border-radius: 0 0 30px 30px;
  border-right: 4px groove #854d2b;
  border-left: 4px ridge #854d2b;
  -webkit-box-shadow: 0px 1px 2px #000;
  box-shadow: 0px 1px 2px #000;
  outline: none;

}
.ui-menu-currency.ui-selectmenu-open ul {
  padding: {
    right: 33px;
    left:40px;
  }
  @include breakpoint($sm) {
    padding: {
      right: 10px;
      left:15px;
    };
  }
  @include breakpoint($xs) {
    padding: {
      right: 10px;
      left:15px;
    }
  }
}

.ui-menu-currency.ui-selectmenu-open .ui-menu::before {

  @include breakpoint($md) {
    background:
            url(../img/master/selects/select_bg_left_s.png) left top repeat-y,
            url(../img/master/selects/select_bg_right_s.png) right top repeat-y;
  }
  @include breakpoint($sm) {
    background:
            url(../img/master/selects/select_bg_left_s.png) left top repeat-y,
            url(../img/master/selects/select_bg_right_s.png) right top repeat-y;
  }

  @include breakpoint($xs) {
    background:
            url(../img/master/selects/select_bg_left_s.png) left top repeat-y,
            url(../img/master/selects/select_bg_right_s.png) right top repeat-y;
  }
  }


.form__section--half .ui-selectmenu-open .ui-menu {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 7px 20px;
  color: #cbc395;
  font-size: 16px;
  background: url(../img/master/forms/select_top_bg.png);
}

.ui-selectmenu-text {
  color: #ffffff;
   &:after {
    content: '';
    font:{
      family: FontAwesome;
      size: 28px;
      style: normal;
    };
    transition: transform .2s;
    color: #ffffff;
    position: absolute;
    top: 5px;
    right: 23px;
  }
}