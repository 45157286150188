$lg : "(min-width : 1200px) and (max-width : 1440px)";
$md : "(min-width : 1023px) and (max-width : 1200px)";
$sm : "(min-width : 768px) and (max-width : 1023px)";
$xs : "(min-width : 300px) and (max-width : 767px)";

$desktop : $lg;
$laptop : $md;
$tablet : $sm;

.container {
  width: 1170px;
  height: 100%;
  margin: 0 auto;
  padding: 0 15px;
  
  @include breakpoint($md) {
    width: 1024px;
    padding: 0 3px;
    box-sizing: border-box;
  }
  @include breakpoint($sm) {
    width: 768px;
    padding: 0 3px;
    box-sizing: border-box;
  }
  @include breakpoint ($xs) {
    width: 640px;
    padding: 0;
  }
}
