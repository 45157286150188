.button {
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  user-select: none;

  &--sp1 {
    width: 360px;
    height: 57px;
  }

  &--sp2 {
    min-width: 142px;
    height: 57px;
  }

  &--sp3 {
    height: 57px;
  }
  &--sp4 {
    min-width: 200px;
    height: 57px;
  }

  &--t1 {

  }

  &--t2 {

  }

  &--t3 {
    font: 18px/55px $font;
    position: relative;
    background:
            url(../img/master/btn-third/btn_third_left_bg.png) left top no-repeat,
            url(../img/master/btn-third/btn_third_right_bg.png) right top no-repeat;
    padding: {
      left: 26px;
      right: 27px;
    };
    @include breakpoint($sm) {
      padding: {
        left: 15px;
        right: 15px;
      };
    }
    @include breakpoint($xs) {
      padding: {
        left: 15px;
        right: 15px;
      };
    }
    color: #d7c59d;
    text-shadow: 0 1px 1px #000000;
    z-index: 0;
    outline: none;

    &::after {
      content: attr(data-copy);
      background-color:#ffffb4;
      filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#ffffb4, endColorstr=#d3b563);
      background-image:-moz-linear-gradient(top, #ffffb4 0%, #d3b563 43%,#ffee9f 68%,#81622a 100%);
      background-image:linear-gradient(top, #ffffb4 0%, #d3b563 43%,#ffee9f 68%,#81622a 100%);
      background-image:-webkit-linear-gradient(top, #ffffb4 0%, #d3b563 43%,#ffee9f 68%,#81622a 100%);
      background-image:-o-linear-gradient(top, #ffffb4 0%, #d3b563 43%,#ffee9f 68%,#81622a 100%);
      background-image:-ms-linear-gradient(top, #ffffb4 0%, #d3b563 43%,#ffee9f 68%,#81622a 100%);
      background-image:-webkit-gradient(linear, right top, right bottom, color-stop(0%,#ffffb4), color-stop(43%,#d3b563),color-stop(68%,#ffee9f),color-stop(100%,#81622a));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% - 52px);
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 26px;
      overflow: hidden;
      background: url(../img/master/btn-third/btn_third_bg.png) repeat-x;
      z-index: -1;
    }

    &:hover {
      background-position: left center, right center;

      &::before {
        background-position: center center;
      }
    }

    &:active, &:visited {
      background-position: left bottom, right bottom;

      &::before {
        background-position: center bottom;
      }
    }
  }

  &--t4 {
    font: 18px/55px $font;
    position: relative;
    background: url(../img/master/btn_demo_bg.png) left top no-repeat;
    padding: {
      left: 26px;
      right: 27px;
    }
    color: #d7c59d;
    text-shadow: 0 1px 1px #000000;
    z-index: 1;
  }
}

.buttons_overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 10px;
}
.games__group {
  display: none;
  position: relative;
  width: 320px;
  height: 52px;
  user-select: none;
  background: url(../img/master/games-group_bg.png) left top no-repeat;
  font: 17px "Roboto", sans-serif;
  color: #cbc395;
  align-items: center;
  justify-content: center;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.6), 0 0 12px rgba(0, 0, 0, 0.6);
  @include breakpoint($xs) {
    display: flex;
  }

  &::after {
    content: '';
    font-family: FontAwesome;
    font-size: 39px;
    line-height: 26px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6), 0 0 12px rgba(0, 0, 0, 0.6);
    color: #cbc395;
    position: absolute;
    right: 33px;
    transition: transform .2s;
  }
  &.opened {
       background-position: center center;
     }
  &.opened::after {
    transform: rotate(180deg);
    transition: transform .2s;
  }
}
.registration {
  display: none;
  @include breakpoint($xs) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 228px;
    height: 54px;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.5);
    font: {
      family: Roboto;
      size: 20px;
      weight: 700;
    };
    text-transform: uppercase;
    box-sizing: border-box;
    background: url(../img/master/reg_bg.png) no-repeat;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: #ffffff;
    }
    &:active, &:visited {
      background: url(../img/master/reg_active_bg.png) no-repeat;
    }
  }
}


.form__button_file {

  width: 195px;
  height: 52px;
  margin: 0 25px;
  background: url(../img/master/btn_sec_bg.png) no-repeat;
  background-size: 100% auto;
  & .file-input-button {
    font: 18px "Roboto", sans-serif;
    color: #cbc395;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-shadow: 0 3px 1px rgba(0, 0, 0, 0.6), 0 0 12px rgba(0, 0, 0, 0.6);
    user-select: none;
  }
  &:hover {
    background-position: center center;
  }
  &:focus, &:active, &:visited {
    background-position: center bottom;
  }
  input {
    display: none;
  }
}

.form__button_file_submit {
  height: 54px;
  width: 195px;
  margin: 0 25px;
  box-sizing: border-box;
  background: url(../img/master/btn_bg.png) center top no-repeat;
  position: relative;
  font: bold 20px "BookmanBold", sans-serif;
  color: #d7c59d;
  text-shadow: 0 2px 3px black;
  text-align: center;
  &:hover {
    background-position: center center;
  }
  &:active, &:visited {
    background-position: center bottom;
  }
}