/*! jQuery UI - v1.12.1 - 2016-10-20
* http://jqueryui.com
* Includes: draggable.css, core.css, resizable.css, button.css, controlgroup.css, checkboxradio.css, datepicker.css, dialog.css, menu.css, selectmenu.css, tabs.css
* Copyright jQuery Foundation and other contributors; Licensed MIT */

.ui-draggable-handle {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix {
  &:before {
    content: "";
    display: table;
    border-collapse: collapse;
  }
  &:after {
    content: "";
    display: table;
    border-collapse: collapse;
    clear: both;
  }
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity = 0);
}

.ui-front {
  z-index: 100;
}

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none;
}

.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block;
}

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ui-resizable {
  position: relative;
}

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle {
  display: none;
}

.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.ui-button {
  padding: .4em 1em;
  display: inline-block;
  position: relative;
  line-height: normal;
  margin-right: .1em;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: visible;
  text-decoration: none;
  &:link, &:visited, &:hover, &:active {
    text-decoration: none;
  }
}

.ui-button-icon-only {
  width: 2em;
  box-sizing: border-box;
  text-indent: -9999px;
  white-space: nowrap;
}

input.ui-button.ui-button-icon-only {
  text-indent: 0;
}

.ui-button-icon-only .ui-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
}

.ui-button.ui-icon-notext .ui-icon {
  padding: 0;
  width: 2.1em;
  height: 2.1em;
  text-indent: -9999px;
  white-space: nowrap;
}

input.ui-button {
  &.ui-icon-notext .ui-icon {
    width: auto;
    height: auto;
    text-indent: 0;
    white-space: normal;
    padding: .4em 1em;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

button.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ui-controlgroup {
  vertical-align: middle;
  display: inline-block;
  > .ui-controlgroup-item {
    float: left;
    margin-left: 0;
    margin-right: 0;
    &:focus, &.ui-visual-focus {
      z-index: 9999;
    }
  }
}

.ui-controlgroup-vertical {
  > .ui-controlgroup-item {
    display: block;
    float: none;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
  .ui-controlgroup-item {
    box-sizing: border-box;
  }
}

.ui-controlgroup .ui-controlgroup-label {
  padding: .4em 1em;
  span {
    font-size: 80%;
  }
}

.ui-controlgroup-horizontal .ui-controlgroup-label + .ui-controlgroup-item {
  border-left: none;
}

.ui-controlgroup-vertical .ui-controlgroup-label + .ui-controlgroup-item {
  border-top: none;
}

.ui-controlgroup-horizontal .ui-controlgroup-label.ui-widget-content {
  border-right: none;
}

.ui-controlgroup-vertical {
  .ui-controlgroup-label.ui-widget-content {
    border-bottom: none;
  }
  .ui-spinner-input {
    width: 75%;
    width: calc( 100% - 2.4em );
  }
  .ui-spinner .ui-spinner-up {
    border-top-style: solid;
  }
}

.ui-checkboxradio-label .ui-icon-background {
  box-shadow: inset 1px 1px 1px #ccc;
  border-radius: .12em;
  border: none;
}

.ui-checkboxradio-radio-label {
  .ui-icon-background {
    width: 16px;
    height: 16px;
    border-radius: 1em;
    overflow: visible;
    border: none;
  }
  &.ui-checkboxradio-checked {
    .ui-icon, &:hover .ui-icon {
      background-image: none;
      width: 8px;
      height: 8px;
      border-width: 4px;
      border-style: solid;
    }
  }
}

.ui-checkboxradio-disabled {
  pointer-events: none;
}

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none;
  .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
  }
  .ui-datepicker-prev, .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
  }
  .ui-datepicker-prev-hover, .ui-datepicker-next-hover {
    top: 1px;
  }
  .ui-datepicker-prev {
    left: 2px;
  }
  .ui-datepicker-next {
    right: 2px;
  }
  .ui-datepicker-prev-hover {
    left: 1px;
  }
  .ui-datepicker-next-hover {
    right: 1px;
  }
  .ui-datepicker-prev span, .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px;
  }
  .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    select {
      font-size: 1em;
      margin: 1px 0;
    }
  }
  select {
    &.ui-datepicker-month, &.ui-datepicker-year {
      width: 45%;
    }
  }
  table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em;
  }
  th {
    padding: .7em .3em;
    text-align: center;
    font-weight: bold;
    border: 0;
  }
  td {
    border: 0;
    padding: 1px;
    span, a {
      display: block;
      padding: .2em;
      text-align: right;
      text-decoration: none;
    }
  }
  .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    button {
      float: right;
      margin: .5em .2em .4em;
      cursor: pointer;
      padding: .2em .6em .3em .6em;
      width: auto;
      overflow: visible;
      &.ui-datepicker-current {
        float: left;
      }
    }
  }
  &.ui-datepicker-multi {
    width: auto;
  }
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
  table {
    width: 95%;
    margin: 0 auto .4em;
  }
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi {
  .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0;
  }
  .ui-datepicker-buttonpane {
    clear: left;
  }
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

.ui-datepicker-rtl {
  direction: rtl;
  .ui-datepicker-prev {
    right: 2px;
    left: auto;
  }
  .ui-datepicker-next {
    left: 2px;
    right: auto;
  }
  .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
  }
  .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
  }
  .ui-datepicker-buttonpane {
    clear: right;
    button {
      float: left;
      &.ui-datepicker-current {
        float: right;
      }
    }
  }
  .ui-datepicker-group {
    float: right;
  }
  .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
  }
}

.ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  left: .5em;
  top: .3em;
}

.ui-dialog {
  position: absolute;
  top: 0;
  left: 0;
  padding: .2em;
  outline: 0;
  .ui-dialog-titlebar {
    padding: .4em 1em;
    position: relative;
  }
  .ui-dialog-title {
    float: left;
    margin: .1em 0;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
  .ui-dialog-titlebar-close {
    position: absolute;
    right: .3em;
    top: 50%;
    width: 20px;
    margin: -10px 0 0 0;
    padding: 1px;
    height: 20px;
  }
  .ui-dialog-content {
    position: relative;
    border: 0;
    padding: .5em 1em;
    background: none;
    overflow: auto;
  }
  .ui-dialog-buttonpane {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin-top: .5em;
    padding: .3em 1em .5em .4em;
    .ui-dialog-buttonset {
      float: right;
    }
    button {
      margin: .5em .4em .5em 0;
      cursor: pointer;
    }
  }
  .ui-resizable-n {
    height: 2px;
    top: 0;
  }
  .ui-resizable-e {
    width: 2px;
    right: 0;
  }
  .ui-resizable-s {
    height: 2px;
    bottom: 0;
  }
  .ui-resizable-w {
    width: 2px;
    left: 0;
  }
  .ui-resizable-se, .ui-resizable-sw, .ui-resizable-ne, .ui-resizable-nw {
    width: 7px;
    height: 7px;
  }
  .ui-resizable-se {
    right: 0;
    bottom: 0;
  }
  .ui-resizable-sw {
    left: 0;
    bottom: 0;
  }
  .ui-resizable-ne {
    right: 0;
    top: 0;
  }
  .ui-resizable-nw {
    left: 0;
    top: 0;
  }
}

.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0;
  .ui-menu {
    position: absolute;
  }
  .ui-menu-item {
    margin: 0;
    cursor: pointer;
    list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  }
  .ui-menu-item-wrapper {
    position: relative;
    padding: 3px 1em 3px .4em;
  }
  .ui-menu-divider {
    margin: 5px 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-width: 1px 0 0 0;
  }
  .ui-state-focus, .ui-state-active {
    margin: -1px;
  }
}

.ui-menu-icons {
  position: relative;
  .ui-menu-item-wrapper {
    padding-left: 2em;
  }
}

.ui-menu {
  .ui-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: .2em;
    margin: auto 0;
  }
  .ui-menu-icon {
    left: auto;
    right: 0;
  }
}

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  .ui-menu {
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 1px;
    .ui-selectmenu-optgroup {
      font-size: 1em;
      font-weight: bold;
      line-height: 1.5;
      padding: 2px 0.4em;
      margin: 0.5em 0 0 0;
      height: auto;
      border: 0;
    }
  }
}

.ui-selectmenu-open {
  display: block;
}

.ui-selectmenu-text {
  display: block;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-selectmenu-button.ui-button {
  text-align: left;
  white-space: nowrap;
  width: 14em;
}

.ui-selectmenu-icon.ui-icon {
  float: right;
  margin-top: 0;
}

.ui-tabs {
  position: relative;
  padding: .2em;
  .ui-tabs-nav {
    margin: 0;
    padding: .2em .2em 0;
    li {
      list-style: none;
      float: left;
      position: relative;
      top: 0;
      margin: 1px .2em 0 0;
      border-bottom-width: 0;
      padding: 0;
      white-space: nowrap;
    }
    .ui-tabs-anchor {
      float: left;
      padding: .5em 1em;
      text-decoration: none;
    }
    li {
      &.ui-tabs-active {
        margin-bottom: -1px;
        padding-bottom: 1px;
        .ui-tabs-anchor {
          cursor: text;
        }
      }
      &.ui-state-disabled .ui-tabs-anchor, &.ui-tabs-loading .ui-tabs-anchor {
        cursor: text;
      }
    }
  }
}

.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {
  cursor: pointer;
}

.ui-tabs .ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 1em 1.4em;
  background: none;
}