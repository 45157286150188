// Wraps interior content in a media query
@mixin breakpoint($querystring) {
  $valid : true;
  @if type-of($querystring) != "string" {
    @warn "querystring: #{$querystring} is not a string";
    $valid : false;
  }
  @if $valid == true {
    @media #{$querystring} {
      @content;
    }
  }
}

@mixin firefox() {
  @-moz-document url-prefix() {
    @content;
  }
}

@mixin placeholder {
  &.placeholder { @content }
  &:-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
}