.form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &__description {
    font-size: 16px;
    line-height: 1.4em;
    margin-bottom: 10px;
    padding-bottom: 20px;
    & .subtitle {
      text-align: left;
      display: block;
      color: #d3b666;
      font-size: 18px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    & p {
      padding-bottom: 15px;
    }
    & ul {
      padding-bottom: 15px;
      position: relative;

      & li {
        padding-left: 15px;

        &::before {
          content: "\2022";
          left: 0;
          position: absolute;
        }
      }

    }
  }

  &__item {
    &_avatar {
      &_title {
        font: 12px $fontThin;
        color: #fffefe;
        margin-top: 11px;
      }

      &_text {
        font: 10px $fontThin;
        color: #fffefe;
      }
    }
  }
  //FORM SECTIONS

  &__section {
    flex: 0 1 320px;
    max-width: 320px;
    margin: auto;
    &--check {
      display: flex;
      justify-content: space-between;
      .form__input--checkbox {
        width: 50%;
      }
    }
    &--wide {
      flex: 0 1 100%;
      display: flex;
      justify-content: center;

      > * {
        flex: 0 1 320px;
      }
    }

    &--center {
      flex: 0 1 460px;
      margin: auto;
      max-width: 100%;
    }

    &--right {
      flex: 0 1 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: flex-end;
      position: absolute;
      right: 0;
      top: 0;

      @include breakpoint($tablet){
        position: static;
      }

      > * {
        width: 420px;
      }
    }
    &--docs {
      width: 100%;
    }

    &--submit {
      margin: {
        top: 12px;
      };
      display: flex;
      justify-content: center;
      flex: 0 1 100%;
      max-width: 100%;
    }
    &--half {
      flex: 0 1 480px;
      @include breakpoint($md) {
        flex: 0 1 425px;
      }
      @include breakpoint($sm) {
        margin: auto;
      }
    }
  }

  //FORM ERRORS

  &__error-message {
    display: none;
    position: absolute;
    color: #ffffff;
    font: 14px "Open Sans";
    line-height: 1;
    margin-top: 3px;
    white-space: normal;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    padding-left: 25px;
    @include breakpoint($xs) {
      font-size: 11px;
    }
  }

  //FORM INPUTS

  &__input {
    margin-bottom: 30px;
    position: relative;
    z-index: 0;

    label {
      font: 0 $fontLight;
      text-transform: uppercase;
      text-align: center;
      display: block;
      width: 100%;
      margin: {
        bottom: 0px;
      };
      cursor: pointer;
      line-height: 1;

      &::before {
        content: "";
        display: inline-block;
        height: 2px;
        width: 49px;
        vertical-align: middle;
        margin-top: -3px;
      }

      &::before {
        margin-right: 15px;
      }

      &::after {
        margin-left: 15px;
      }
    }

    &--text, &--email, &--password {

      &.focused::before {
        background-position: left -84px;
      }

      input {
        height: 58px;
        width: 100%;
        position: relative;
        outline: none;
        box-sizing: border-box;
        color: #ffffff;
        font: 16px "Roboto", sans-serif;
        padding: {
          left: 24px;
          right: 24px;
          bottom: 4px;
        };
        background: rgba(0, 0, 0, 0.5);
        border-style: ridge;
        border-top: 4px ridge #915e3b;
        border-bottom: 4px groove #915e3b;
        border-radius: 30px;
        border-right: 4px groove #854d2b;
        border-left: 4px ridge #854d2b;
        -webkit-box-shadow: 0px 1px 2px #000;
        box-shadow: 0px 1px 2px #000;

        &:focus {
          background: rgba(0, 0, 0, 0.3);
        }

        &:focus::-webkit-input-placeholder {transition: opacity 0.3s ease;}


        @include placeholder {
          font: 16px $fontLight;
          color: #999999;
          font-style: italic;
          font-width: 100;
        }
      }
    }



    &--captcha {
      position: relative;
      input {
        padding-right: 190px !important;
      }
      img {
        position: absolute;
        right: 4px;
        bottom: 4px;
        max-height: 50px;
        border-radius: 0 25px 25px 0;
        z-index: 0;
      }
    }
.updcaptcha {
  &::before {
    content: '';
    width: 23px;
    height: 23px;
    background: url(../img/master/forms/upgrade-arrow.png);
    position: absolute;
    top: 15px;
    right: -40px;
    transition: all 400ms 0s ease-in-out;
  }
  &:hover::before {
    transform: rotate(180deg);
  }
}
    //FORM CHECKBOXES
    &--checkbox {
         height: 58px;
        display: flex;
        align-items: center;
      label {
        padding-left: 43px;
        margin-bottom: 0;
        height: 31px;
        font: 16px "RobotoLight", sans-serif;
        cursor: pointer;
        text-align: left;
        text-transform: none;
        background: url(http://csscheckbox.com/checkboxes/u/csscheckbox_8e8ae1663dc462c9336e4a0558f2395a.png) 0 0 no-repeat;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        a {
          color: #ffffff;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        &::before, &::after {
          display: none;
        }
      }

      [type="checkbox"]{
        position:absolute;
        z-index:-1000;
        left:-1000px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height:1px;
        width:1px;
        margin:-1px;
        padding:0;
        border:0;

        &:checked + label {
          background-position: 0 -32px;
        }
      }
    }

    /*=============================*/
    //RADIO BUTTONS
    /*=============================*/


    &--radio {
      height: 58px;
      display: flex;
      align-items: center;
      & span {
        width: 100%;
        display: flex;
      }
      label {
        padding-left: 43px;
        margin-bottom: 0;
        height: 32px;
        line-height: 32px;
        font: {
          size: 16px;
          family: "RobotoLight", sans-serif;
        };
        cursor: pointer;
        text-align: left;
        text-transform: none;
        background: url(http://csscheckbox.com/checkboxes/u/csscheckbox_34364d6b1ca0729ff128f327957298aa.png) 0 0 no-repeat;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        a {
          color: #598cff;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        &::before, &::after {
          display: none;
        }
      }

      [type="radio"]{
        position:absolute; z-index:-1000; left:-1000px; overflow: hidden; clip: rect(0 0 0 0); height:1px; width:1px; margin:-1px; padding:0; border:0;

        &:checked + label {
          background-position: 0 -32px;
        }
      }
    }


    //FORM TEXTAREA

    &--textarea {
      textarea {
        resize: none;
        box-sizing: border-box;
        width: 100%;
        height: 145px;
        color: #fff;
        padding-top: 17px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 19px;
        background: rgba(0, 0, 0, 0.5);
        border-style: ridge;
        border-top: 4px ridge #915e3b;
        border-bottom: 4px groove #915e3b;
        border-radius: 30px;
        border-right: 4px groove #854d2b;
        border-left: 4px ridge #854d2b;
        box-shadow: 0px 1px 2px #000;
        outline: none;
        font: 16px $fontLight;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
        @include placeholder {
          font: 16px $fontLight;
          color: #999999;
          font-style: italic;
        }

        &:focus {
          background: rgba(0, 0, 0, 0.3);
        }
      }
      &.focused::before {
        background-position: left -156px;
      }
    }

    //FORM INPUT FILE

    &--file {
      position: absolute;
      height: 48px;
      bottom: -48px;
      left: 0;
      right: 0;
      width: 100%;
      background: rgba(0, 0, 0, .6);
      box-sizing: border-box;
      padding-left: 67px;
      cursor: pointer;
      overflow: hidden;
      transition: bottom .3s;
      margin: {
        bottom: 0;
      };

      [type="file"]{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        cursor: pointer;
        appearance: none;
        opacity: 0;

        &:hover {
          cursor: pointer;
        }
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 31px;
        height: 21px;
        background: url(../img/profile/add_bg.png) no-repeat;
        top: 50%;
        left: 21px;
        transform: translateY(-50%);
        cursor: pointer;
      }

      &:hover::before {
        opacity: .6;
      }

      &:focus::before {
        opacity: .8;
      }
    }

    &--error {
      input {
        background-color: rgba(96, 72, 68, 0.5);
        padding-right: 55px;
        border-top: 4px ridge #ff0000;
        border-bottom: 4px groove #ff0000;
        border-right: 4px groove #ff0000;
        border-left: 4px ridge #ff0000;
      }
      textarea {
        background-color: rgba(96, 72, 68, 0.5);
        border-top: 4px ridge #ff0000;
        border-bottom: 4px groove #ff0000;
        border-right: 4px groove #ff0000;
        border-left: 4px ridge #ff0000;
      }
      &:not(.form__input--captcha)::after {
        content: '\f00d';
        position: absolute;
        color: #ff0000;
        font-family: FontAwesome;
        font-size: 24px;
        right: 30px;
        top: 15px;
      }
      &::before {
        background-position: left bottom;
      }
    }

    &--error .form__error-message {
      display: block;
    }

    &--success {
      input {
        border-top: 4px ridge #547f14;
        border-bottom: 4px groove #547f14;
        border-right: 4px groove #547f14;
        border-left: 4px ridge #547f14;
        padding-right: 55px;
      }
      &::before {
        background-position: left -168px;
      }
      &::after {
        content: '';
        position: absolute;
        color: #25b711;
        font-family: FontAwesome;
        font-size: 24px;
        right: 30px;
        top: 15px;
      }
    }

  }

  //CONTACT FORM

  &--feedback {


    .form__section--heading {
      flex: 0 1 100%;
      margin-bottom: 30px;

      > * {
        font: 23px $fontLight;
        color: #fff;
        text-align: center;
        margin: {
          top: 25px;
        };
        line-height: 1;
      }
    }
  }

  //REGISTRATION FORM

  &--register {
    margin-top: 30px;

    .form__input--checkbox {
      margin-top: 24px;
      padding-left: 30px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 58px;
    }

    .form__section--submit {
      & > * {
        max-width: 360px;
        min-width: 360px;
      }
    }
  }

  //AVATAR FORM

  &--avatar {
    margin: {
      bottom: 32px;
    };

    .form__section {
      position: relative;
      overflow: hidden;
      border-radius: 10px;

      &:hover .form__input--file {
        transition: bottom .2s;
        bottom: 0;
      }
    }
  }

  //PROFILE FORM

  &--profile {
    margin: {
      bottom: 60px;
    };

    .form__section--submit {
      & > * {
        max-width: 360px;
        min-width: 360px;
      }
    }
  }


  //RESTORE PASSWORD FORM

  &--password {
    justify-content: center;
    margin-top: 25px;
  }

  //LOGIN FORM
  &--login {
    .form__section--submit {
      min-width: 100%;
    }
  }
  &--sign-in {
    margin-top: 30px;

    .form__section--submit {
      & > * {
        min-width: 100%;
      }
    }
  }
  &--login, &--restore {
    justify-content: center;

      & input {
      }
    & .form__input--checkbox {
      margin-left: 20px;
      margin-bottom: 15px;
      label {
        display: flex;
        align-items: center;
        width: auto;
      }
    }
  }
  //DEPOSIT FORM

  &--deposit {
    margin-top: 17px;
    justify-content: center;

    .form__section--links {
      display: flex;
      justify-content: center;

      > * {
        margin-right: 25px;

        &:hover {
          filter: brightness(110%);
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .form__section--submit {
      margin: {
        top: 23px;
        bottom: 50px;
      };
    }
  }
}



/*=============================*/
//SELECT MENU
/*=============================*/



.ui-selectmenu-button-open .ui-selectmenu-text:after {
  transform: rotate(180deg);
  transition: transform .2s;
}

/*=============================*/
//RADIO
/*=============================*/

input[type=radio].radio {
  position:absolute; z-index:-1000; left:-1000px; overflow: hidden; clip: rect(0 0 0 0); height:1px; width:1px; margin:-1px; padding:0; border:0;
}

input[type=radio].radio + label.radio-label {
  padding-left:36px;
  height:32px;
  display:inline-block;
  line-height:32px;
  background-repeat:no-repeat;
  background-position: 0 0;
  font-size:32px;
  vertical-align:middle;
  cursor:pointer;

}

input[type=radio].radio:checked + label.radio-label {
  background-position: 0 -32px;
}
label.radio-label {
  background-image:url(http://csscheckbox.com/checkboxes/u/csscheckbox_34364d6b1ca0729ff128f327957298aa.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form__error-message-checkbox {
  text-align: center;
  padding-top: 5px;
}

/*=============================*/
//DOCS UPLOAD
/*=============================*/

.docs__form {
  margin-bottom: 125px;
  display: flex;
  justify-content: center;
  &__file {
    text-align: center;
    margin-top: 15px;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    & p {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
textarea::-webkit-scrollbar {
  position: absolute;
  right: -100%;
}

.form__input--checkbox.form__input--success::after {
  content: none;
}
.notification .form__section--submit {
  min-width: 100%;
}


.form__input.form__input--text.phoneselect {
  position: relative;
  z-index: 1;
}
.phoneselect {
  .intl-tel-input {
    width: 100%;
  }
  .intl-tel-input .flag-dropdown:hover .selected-flag {
    background-color: transparent !important;
  }
  .intl-tel-input input {
    padding-left: 60px !important;
    padding-top: 5px;
  }
  .intl-tel-input.open input {
    border-radius: 30px 30px 0 0;
    border-bottom-color: transparent;
  }
  .intl-tel-input .selected-flag {
    width: 71px;
    padding: 0 0 0 23px;
  }
  .intl-tel-input .selected-flag .arrow {
    border-top: 4px solid #ffffff;
  }
  .intl-tel-input .selected-flag .arrow.up {
    border-top: none;
    border-bottom: 4px solid #ffffff;
  }
  .intl-tel-input .country-list {
    position: relative !important;
    z-index: 2;
    list-style: none;
    text-align: left;
    margin: 0 0 0 -1px;
    max-height: 200px;
    overflow-y: scroll;
    box-sizing: border-box;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    color: #cbc395;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.8);
    border-top: none;
    border-bottom: 4px groove #915e3b;
    border-radius: 0 0 30px 30px;
    border-right: 4px groove #854d2b;
    border-left: 4px ridge #854d2b;
    box-shadow: 0 1px 2px #000;
    outline: none;
    top: -2px !important;
  }
  .intl-tel-input .country-list .country {
    padding: 11px 0;
    user-select: none;
  }
  .intl-tel-input .country-list .country:not(:last-child) {
    border-bottom: solid 1px rgba(149, 149, 149, 0.5);
  }

  .intl-tel-input.allow-dropdown.open input {
    padding-right: 6px;
    padding-left: 80px;
    margin-left: 0;
    border-radius: 30px 30px 0 0;
    border-bottom-color: transparent;
  }
  .intl-tel-input.open .selected-flag .iti-arrow {
    border-top: 0 solid #ffffff;
    border-bottom: 8px solid #ffffff;
  }
}


.register__layout .phoneselect .intl-tel-input .country-list {
  width: 480px;
  @include breakpoint($md) {
    width: 425px;
  }

}
.profile__layout .phoneselect .intl-tel-input .country-list {
  width: 320px;
  @include breakpoint($md) {
    width: 270px;
  }
}