.ui-dialog {
  outline: none;
  z-index: 2000;
  box-sizing: border-box;
  position: absolute;
  background: url('../img/master/modal-bg.png');
  background-color: #5a5858;
  border: solid 1px #a09887;
  @include breakpoint($xs) {
    max-width: 560px;
    right: 0 !important;
    margin: auto !important;
    left: 0 !important;
  }
  &::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: 9px;
    height: calc(100% + 4px);
    background: url("../img/master/left-top.png") left -1px top no-repeat, url("../img/master/left-bottom.png") left -1px bottom -2px no-repeat;
  }
  &::after {
    content: '';
    position: absolute;
    right: -1px;
    top: -1px;
    bottom: -1px;
    width: 9px;
    height: calc(100% + 4px);
    background: url("../img/master/right-top.png") right -1px top no-repeat, url("../img/master/right-bottom.png") right -1px bottom -2px no-repeat;
  }

  .ui-dialog-content {
    padding: {
      top: 30px;
      right: 30px;
      bottom: 50px;
      left: 30px;
    };
    overflow: hidden;
  }

  .ui-dialog-titlebar {
    padding: 0;
  }

  .ui-dialog-titlebar-close {
    padding: 0;
    margin: 0;
    z-index: 10000;
    width: 18px;
    height: 18px;
    top: 20px;
    right: 20px;
    background: url(../img/master/close.png) center center no-repeat;
    &:hover {
      filter: brightness(140%);
    }
  }


}


.ui-widget-overlay {
  background: $modalOverlayBG;
  cursor: pointer;
}
.session-status {

    & .ui-dialog-titlebar-close {
      display: none;
    }

  }

.modal {
  display: none;
  border: 1px solid #fec606;
  border-radius: 5px;
  outline: none;
  background: #052605;
  &__title {
    text-align: center;
    margin-bottom: 35px;
    font-family: "28 days", sans-serif;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
  }
  &__subtitle {
    color: #d3b666;
    font-size: 18px;
    text-align: center;
    padding-bottom: 30px;
  }
  &__content {
    text-align: center;
    font-size: 16px;
    & p {
      padding-bottom: 15px;
      &:last-of-type {
        padding-bottom: 0;
      }
    }

    & span {
      font-weight: 700;
    }
    &__success {
      font-size: 16px;
    }
    &__currency {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 40px;
      & input[type=radio].radio + label.radio-label {
        font-size: 16px;
        padding-left: 45px;
        margin: 0 35px;
      }

    }
    &__buttons {
      display: flex;
      justify-content: space-around;
      padding-top: 40px;
      align-items: center;
      &--deposits {
        width: 320px;
        margin: auto;
      }
    }
    &__bonuses {
      text-align: left;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      &--left {
        flex: 1 0 200px;
        box-sizing: border-box;
        @include breakpoint($xs) {
          flex: 1 0 175px;
        }

      }
      & h2 {
        padding-bottom: 25px;
      }
      &--right {
       .modal__content__buttons {
          display: flex;
          justify-content: space-between;
          padding-top: 15px;
          align-items: center;
          width: 320px;
        }
      }
    }

  }
  &__notification {
    padding: {
      top: 20px;
      bottom: 15px;
    };
    color: #e90c0c;
    font-size: 13px;
    & span {
      font-weight: 700;
    }
  }
  &__bottom {
    text-align: center;
    margin-top: 35px;
  }
  &__link {
    color: #d7c59d;
    font-size: 18px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: #ffffff;
    }
  }
}